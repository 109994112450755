/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

import { Header, HistoryItem } from "./ScoreHistoryStyle";

const ScoreHistorySkeleton = () => {
  const skeletonRepeat = 5;
  return (
    <Flex width="1" height="100%" flexDirection="column">
      <Header>
        <Skeleton circle width="48px" height="48px" />
        <Flex flexDirection="column" ml={2}>
          <Skeleton width="300px" height="18px" />
          <Skeleton width="375px" height="22px" />
        </Flex>
      </Header>
      <Flex flexDirection="column">
        {[...Array(skeletonRepeat)].map((_value, index) => (
          <HistoryItem key={index}>
            <Skeleton circle width="26px" height="26px" />
            <Flex flexDirection="column" flexGrow="1" ml={2}>
              <Flex spaceChildren={5}>
                <Skeleton width="280px" height="19px" />
                <Skeleton width="85px" height="19px" />
                <Flex ml="auto">
                  <Skeleton circle width="20px" height="20px" />
                </Flex>
              </Flex>
              <Skeleton width="110px" height="14px" />
            </Flex>
          </HistoryItem>
        ))}
      </Flex>
    </Flex>
  );
};

export default ScoreHistorySkeleton;
