import styled from "styled-components";
import theme from "styles/theme";

export const Container = styled.div`
  width: 100%;
  max-height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 8rem;
  margin-top: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 12rem;
    margin-right: 2rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: initial;
  justify-content: space-between;
  padding-left: 2rem;

  > img {
    position: relative;
    margin-top: 4rem;
    width: 8rem;
  }

  ${({ width }) => (width ? `width: ${width};`:`width: 25rem;`)}
  ${({ height }) => (height ? `height: ${height};`:`height: 100%;`)}

  ${({ hasBorder }) => (hasBorder ? `border-left: 2px solid ${theme.colors.gray};` : `border: none;`)}
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
`;

export const TagText = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  color: ${theme.colors.secondaryColor};
`;

export const Title = styled.span`
  font-family: "Rubik", sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 2.2rem;

  ${({ width }) => (width ? `width: ${width};`:`width: 30rem;`)}
`;

export const Subtitle = styled.span`
  font-family: "Rubik", sans-serif;
  font-size: 1.1rem;
  margin-top: 1rem;
  color: ${theme.colors.textConfig};

  ${({ width }) => (width ? `width: ${width};`:`width: 30rem;`)}
`;
export const Body = styled.div`
  width: 75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    position: relative;
    max-height: 23rem;
    margin-top: 2.8rem;
    z-index: 2;
  }

  .img-thank{
    margin-left:1rem;
    margin-top: 0rem;
    max-height: 22rem;
  }

  ${({ height }) => (height ? `height: ${height};` : `height: 25rem`)}
`;

export const Form = styled.div`
  width: 18rem;
  height: 22rem;
  margin-left: 1rem;
`;

export const Footer = styled.footer`
  height: 3.55rem;
  width: 100%;
  background-color: ${theme.colors.secondaryColor};
`;
