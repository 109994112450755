import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  showSection: ["payload"],
});

const INITIAL_STATE = {
  sectionToShow: "",
};

const showSection = (state, { payload }) => ({
  ...state,
  sectionToShow: payload,
});

export default createReducer(INITIAL_STATE, {
  [Types.SHOW_SECTION]: showSection,
});
