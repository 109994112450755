import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";
import InnerImageZoom from "react-inner-image-zoom";

export const GeneralContainer = styled(Flex)(
  css({
    width: "100%",
    marginBottom: "120px",
    padding: "30px 0px",
    paddingLeft: "80px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 342px)": {
      paddingLeft: "80px",
    },

    "@media (min-width: 366px) and (max-width: 386px)": {
      paddingLeft: "40px",
    },
    "@media (min-width: 386px) and (max-width: 490px)": {
      paddingLeft: "60px",
    },
  }),
  ({ aboutUs }) =>
    aboutUs &&
    css({
      alignItems: "center",
    }),
);

export const TextContent = styled("div")(
  css({
    maxWidth: "600px",
    marginBottom: "40px",
  }),
  ({ aboutUs }) =>
    aboutUs &&
    css({
      maxWidth: "100%",
      marginBottom: "20px",
    }),
);

export const ImgContainer = styled(InnerImageZoom)(
  css({
    maxWidth: "600px",
    marginBottom: "40px",
    "@media (max-width: 680px)": {
      maxWidth: "100%",
    },
    ".iiz__btn.iiz__hint": {
      display: "none",
    },
  }),
);

export const Text = styled("p")(
  css({
    color: "gray",
    marginBottom: 0,
    fontSize: "18px",
    fontFamily: "text",
  }),
);

export const ContentDetails = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    paddingTop: "16px",
    position: "relative",
    alignItems: "flex-start",
    flexDirection: "column",
  }),
);

export const Image = styled("img")(
  css({
    width: "100%",
    borderRadius: "12px",

    "@media (min-width: 767px)": {
      width: "600px",
    },
  }),
);

export const CardContentCarrousel = styled("div")(
  css({
    width: "270px",
    borderRadius: "12px",

    "@media (min-width: 359px) and (max-width: 539px)": {
      width: "350px",
    },

    "@media (min-width: 540px) and (max-width: 766px)": {
      width: "500px",
    },

    "@media (min-width: 767px)": {
      width: "590px",
    },
  }),
);

export const TextContainer = styled("div")(
  css({
    margin: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }),
);

export const KnowMoreButton = styled(Button)(
  css({
    width: "272px",
    height: "52px",
    margin: "30px 0px 30px 0px",
    textTransform: "capitalize",
    borderRadius: "8px",
    minWidth: 120,
    padding: "16px 96px",
    svg: {
      width: "20px",
      path: {
        stroke: "white",
      },
    },
  }),
);
