import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import avatar from "assets/img/avatar.png";
import Flex from "../../core/Grid/Flex";

export const ProfileHeader = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 375px)": {
      flexDirection: "column",
    },
  }),
);

export const ProfileForm = styled("form")(
  css({
    width: "auto",
    display: "flex",
    flexDirection: "column",
    "@media(max-width: 1024px)": {
      height: "1740px",
    },
  }),
);

export const ProfileImage = styled("div")(
  css({
    width: 126,
    height: 126,
    borderRadius: "100%",
  }),
  ({ image }) =>
    image
      ? css({
          background: `url(${image}) center / cover `,
        })
      : css({ background: `url(${avatar}) center / cover ` }),
);

export const ImageName = styled("h4")(
  css({
    fontSize: 0,
    m: "auto 0",
    textTransform: "uppercase",
    color: `${theme.colors.gray}`,
    fontFamily: `${theme.fonts.textLight}`,
    h3: {
      fontSize: 2,
      textTransform: "initial",
      fontFamily: `${theme.fonts.text}`,
      color: `${theme.colors.charcoalGrey}`,
    },
  }),
);

export const ButtonImage = styled("input")(
  css({
    display: "none",
  }),
);

export const LabelButtonImage = styled("label")(
  css({
    mt: 89,
    height: 20,
    fontSize: 0,
    opacity: 0.6,
    color: "white",
    borderRadius: 4,
    textAlign: "center",
    backgroundColor: "#000",
    textTransform: "uppercase",
    fontFamily: `${theme.fonts.text}`,
    ":hover": {
      cursor: "pointer",
    },
  }),
  ({ image }) =>
    image
      ? css({
          ml: 34,
          width: 57,
        })
      : css({
          ml: 30,
          width: 68,
        }),
);

export const LabelButtonRemove = styled(LabelButtonImage)(
  css({
    mt: "8px",
    mb: 0,
  }),
);

export const ContainerForm = styled.div`
  padding: 15px;
  width: 500px;
`;

export const Label = styled.div`
  color: ${theme.colors.labelColor};
  font-size: 1em;
  margin-bottom: 0px;
`;

export const ContainerTags = styled.div`
  margin-bottom: 2em;
  background: ${theme.colors.focusInput};
  border-bottom: ${theme.colors.grayDefaultColor};
  border-radius: 8px 8px 0 0;
  padding: 13px;
`;

export const BoxProfile = styled(Flex)`
  display: block;
  @media (max-width: 400px) {
    padding-bottom: 184px;
  }
`;
