import React from "react";
import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";
import { FormattedMessage } from "react-intl";
import { Types } from "../ducks/guestAuth";
import { GUEST_AUTH } from "../../utils/constants/endpoints";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { style } from "../../style";

function* guestAuthRequest({ payload }) {
  const body = {
    username: payload.email,
    password: payload.password,
  };

  try {
    yield axios({
      method: "POST",
      url: GUEST_AUTH,
      headers: {
        "host-header": style.api.hostHeader,
      },
      data: body,
    })
      .then((response) => {
        localStorage.setItem(
          "headers",
          JSON.stringify({ ...response.headers, source: "totem" }),
        );
        const { headers } = response;
        localStorage.setItem("adal-access-token", headers["adal-access-token"]);
      })
      .catch((error) => {
        throw error;
      });

    yield put({
      type: Types.GUEST_AUTH_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      // eslint-disable-next-line react/react-in-jsx-scope
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
      buttons: [
        {
          title: "OK",
        },
      ],
    };

    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });

    yield put({
      type: Types.GUEST_AUTH_FAILURE,
      error,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GUEST_AUTH_REQUEST, guestAuthRequest);
}
