/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { style } from "style";

import Flex from "components/core/Grid/Flex";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  smartFormatterDateNotifications,
  linkNotification,
  history,
} from "helpers/sharedHelpers";
import { Creators as NotificationsActions } from "store/ducks/notifications";
import { Creators as CustomMenuActions } from "store/ducks/customMenu";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import logoMenu from "assets/img/logoMenu.png";
import menuIcon from "assets/img/icons/menuIcon.svg";
import backIcon from "assets/img/icons/backIcon.svg";
import searchIcon from "assets/img/icons/searchIcon.svg";
import MuralIcons from "assets/img/icons/muralIcons.svg";
import FavoriteIcons from "assets/img/icons/favoriteIcon.svg";
import EventsIcons from "assets/img/icons/eventsIcons.svg";
import BirthdaysIcons from "assets/img/icons/birthdaysIcons.svg";
import AcademyIcons from "assets/img/icons/academyIcons.svg";
import StoreIcons from "assets/img/icons/storeIcons.svg";
import SquadIcons from "assets/img/icons/squadIcons.svg";
import NozIcon from "assets/img/icons/iconCoin.svg";
import ArquivesIcons from "assets/img/icons/arquivesIcons.svg";
import ConfigurationsIcons from "assets/img/icons/configurationsIcons.svg";
import AppsIcons from "assets/img/icons/externalLink.svg";
import SunIcons from "assets/img/icons/sunIcon.svg";
import GroupPardini from "assets/img/icons/groupPardini.svg";
import AboutUs from "assets/img/icons/aboutUs.svg";
import HoleriteIcon from "assets/img/icons/holeriteIcon.svg";
import CustomIcon from "assets/img/icons/custom_menu.svg";
import CibraIcon from "assets/img/icons/cibraIcon.svg";
import SocialResponsibilityIcon from "assets/img/icons/socialResponsibilityIcon.svg";
import { ReactComponent as IconNotifications } from "assets/img/icons/notification.svg";
import { ReactComponent as GroupPardiniComunication } from "assets/img/icons/groupPardiniComunication.svg";
import API from "utils/API";
import { urlsFunction } from "../../../utils/constants/urls";
import { convertToSlug } from "helpers/sharedHelpers";

import SkeletonNotifications from "../HeadProfile/SkeletonNotifications";
import * as S from "../HeadProfile/HeadProfileStyle";
import * as T from "./MenuStyle";

const renderIcon = (nameIcon) => {
  switch (nameIcon) {
    case "MuralIcons":
      return MuralIcons;
    case "GroupPardini":
      return GroupPardini;
    case "AboutUs":
      return AboutUs;
    case "FavoriteIcons":
      return FavoriteIcons;
    case "EventsIcons":
      return EventsIcons;
    case "NozIcon":
      return NozIcon;
    case "BirthdaysIcons":
      return BirthdaysIcons;
    case "AcademyIcons":
      return AcademyIcons;
    case "StoreIcons":
      return StoreIcons;
    case "SquadIcons":
      return SquadIcons;
    case "ArquivesIcons":
      return ArquivesIcons;
    case "ConfigurationsIcons":
      return ConfigurationsIcons;
    case "HoleriteIcon":
      return HoleriteIcon;
    case "AppsIcons":
      return style.variables.gamificationEnabled ? AppsIcons : SunIcons;
    case "CibraIcon":
      return CibraIcon;
    case "SocialResponsibilityIcon":
      return SocialResponsibilityIcon;
    default:
      return CustomIcon;
  }
};

const Menu = ({ show, search }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(null);
  const [showSearch, setShowSearch] = useState(null);
  const location = useLocation();

  const { notViewdNotifications } = useSelector((state) => state.user);
  const [itensNotications, setItensNotifications] = useState([]);
  const { notifications, isLoading: isLoadingNotifications } = useSelector(
    (state) => state.notifications,
  );

  const { isLoading: isLoadingUser } = useSelector((state) => state.user);
  const [urls, setUrls] = useState(null);
  const [itensMenu, setItensMenu] = useState(null);

  useEffect(() => {
    if (!urls) return;

    API.get("custom_menu_items").then(({ data }) => {
      const arr = [];

      data.ordered_menus.map((item_menu) => {
        const menu = urls.ROUTES_MENU.find(
          (item) => item.name === item_menu.kind,
        );

        if (!menu) {
          arr.push(item_menu);
          return;
        }

        menu.active = item_menu.active;
        menu.title = item_menu.title;
        menu.kind = item_menu.kind;

        arr.push(menu);
      });

      setItensMenu(arr);
      const ordered_menus = data.ordered_menus;
      dispatch(CustomMenuActions.getMenu(ordered_menus));
    });
  }, [urls]);

  useEffect(() => {
    if (!isLoadingUser) {
      setUrls(urlsFunction);
    }
  }, [isLoadingUser]);

  const ViewAllNotifications = () => {
    history.push(urls.ROUTES.MY_NOTIFICATIONS);
  };
  useEffect(() => {
    setItensNotifications(notifications.filter((_, index) => index <= 2));
  }, [notifications]);

  const renderLabel = (id) => intl.messages[`menu.${id}`];

  useEffect(() => {
    if (showMenu !== null) {
      show(showMenu);
    }
  }, [show, showMenu]);

  useEffect(() => {
    if (showSearch !== null) {
      search(showSearch);
    }
  }, [search, showSearch]);

  const popoverNotifications = (
    <Popover
      style={{
        borderRadius: 4,
        width: 304,
        border: "none",
        marginTop: 15,
        boxShadow:
          "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
      }}
    >
      <S.NotificationsTitleDate>
        {intl.messages["myNotifications.textPage.notifications"]}
      </S.NotificationsTitleDate>
      {isLoadingNotifications ? (
        <SkeletonNotifications />
      ) : itensNotications.length > 0 ? (
        <>
          <S.Notifications>
            {itensNotications.map((notification) => {
              const {
                title,
                idelement,
                created_at,
                notified,
                kind,
                canvas_publication_id,
              } = notification;

              return (
                <Link
                  to={linkNotification({
                    kind,
                    idelement,
                    title,
                    canvas_publication_id,
                  })}
                  key={idelement}
                  title={`${intl.messages["general.textPage.clickToGoToContant"]}`}
                >
                  <Flex justifyContent="space-between">
                    <Flex flexDirection="column">
                      <S.NotificationNew>{title}</S.NotificationNew>
                      <S.NotificationsTitleDate>
                        {smartFormatterDateNotifications(created_at, "LLL")}
                      </S.NotificationsTitleDate>
                    </Flex>
                    {!notified && <S.NotificationCircle />}
                  </Flex>
                </Link>
              );
            })}
          </S.Notifications>
          <S.ViewAll
            title={intl.messages["general.buttons.goListNotifications"]}
            onClick={() => ViewAllNotifications()}
          >
            {intl.messages["general.buttons.viewAll"]}
          </S.ViewAll>
        </>
      ) : (
        <Flex>
          <S.NotificationNew>
            {intl.messages["myNotifications.textPage.notNotifications"]}
          </S.NotificationNew>
        </Flex>
      )}
    </Popover>
  );

  const publicationUrlWithSlug = (id, title) =>
    !id || !title
      ? ""
      : `${urls.ROUTES.DETAILS_PUBLICATION}/${convertToSlug(title)}/${id}`;

  const openNonExistingPublicationModal = (route) =>
    route.kind === "publication" && !route.canvas_publication_id && !route.publication_title && 
    dispatch(
      ModalAlertActions.setAlertsRequest({
        type: "errors",
        title: intl.messages["modal.textPage.somethingWentWrong"],
        msg: intl.messages["menu.nonExistingPublication"],
        buttons: [
          {
            title: intl.messages["general.buttons.ok"],
            event: () => {},
          },
        ],
      }),
    );

  const renderMenu = () =>
    itensMenu.map(
      (route) =>
        route.active &&
        route.kind !== "notifications" && (
          <Link
            title={`${intl.messages[`general.textPage.goTo`]} ${route.title}`}
            false
            key={route.id}
            to={{
              pathname:
                route.patch ||
                route.link ||
                publicationUrlWithSlug(
                  route.canvas_publication_id,
                  route.publication_title,
                ),
              state: { pageTitle: route.title },
            }}
            target={!route.patch && route.kind !== "publication" && "_blink"}
            onClick={()=>openNonExistingPublicationModal(route)}
          >
            <T.OptionMenu
              IndicateTheMenu={
                route.patch === location.pathname ||
                (route.kind === "publication" &&
                  location.pathname ===
                    publicationUrlWithSlug(
                      route.canvas_publication_id,
                      route.publication_title,
                    ))
              }
            >
              <T.IconOption src={renderIcon(route.nameIcon)} />
              {route.title}
            </T.OptionMenu>
          </Link>
        ),
    );

  function verifyBirthdaysAndTeam(menu) {
    if (style.names.nameApp === "Pintou Parceria Suvinil") {
      if (
        menu.name !== "birthdays" &&
        menu.name !== "team" &&
        menu.name !== "groupPardini" &&
        menu.name !== "aboutUs"
      )
        return true;
      return false;
    }
    return true;
  }

  const renderMenuWithoutCustomItems = () =>
    Object.keys(urls.ROUTES_MENU).map(
      (route) =>
        urls.ROUTES_MENU[route].showMenu &&
        verifyBirthdaysAndTeam(urls.ROUTES_MENU[route]) && (
          <Link
            title={`${intl.messages[`general.textPage.goTo`]} ${renderLabel(
              urls.ROUTES_MENU[route].name,
            )}`}
            false
            key={urls.ROUTES_MENU[route].id}
            to={{
              pathname: urls.ROUTES_MENU[route].patch,
              state: { pageTitle: null },
            }}
          >
            <T.OptionMenu
              IndicateTheMenu={
                urls.ROUTES_MENU[route].patch === location.pathname
              }
            >
              <T.IconOption
                src={renderIcon(urls.ROUTES_MENU[route].nameIcon)}
              />
              {renderLabel(urls.ROUTES_MENU[route].name)}
            </T.OptionMenu>
          </Link>
        ),
    );

  const toggleMenu = (show) =>
    show === null ? setShowMenu(true) : setShowMenu(!show);
  const toggleSearch = (show) =>
    show === null ? setShowSearch(true) : setShowSearch(!show);

  return (
    <T.ContainerMenu isPardini={style.variables.child_profiles}>
      <T.LinkLogo
        backMenu={showMenu || showSearch}
        isPardini={style.variables.child_profiles.length > 0}
        to="/"
        title={intl.messages[`general.textPage.returnToHome`]}
      >
        <img alt="Logo" src={style.icons.menuIcon || logoMenu} />
      </T.LinkLogo>
      <Flex spaceChildren={2}>
        {!showSearch && (
          <T.Icon
            onClick={() => toggleMenu(showMenu)}
            src={showMenu ? backIcon : menuIcon}
          />
        )}
        {!showMenu &&
          (location.pathname === "/favoritos" || location.pathname === "/") && (
            <T.Icon
              onClick={() => toggleSearch(showSearch)}
              src={showSearch ? backIcon : searchIcon}
            />
          )}
        {!showMenu && !showSearch && (
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={popoverNotifications}
          >
            <T.ProfileNotifications
              title={intl.messages["myNotifications.textPage.notifications"]}
              onClick={() =>
                dispatch(NotificationsActions.getNotifications({ page: 1 }))
              }
              unviewdNotifications={notViewdNotifications}
            >
              <IconNotifications />
            </T.ProfileNotifications>
          </OverlayTrigger>
        )}
      </Flex>
      {style.names.nameApp !== "sample" &&  style.names.nameApp !== "PetroRio"//customers who use the custom menu
        ? urls && (
            <>
              <T.ItensMobile
                show={showMenu}
                isPardini={style.variables.child_profiles?.length > 0}
              >
                {renderMenuWithoutCustomItems()}
              </T.ItensMobile>
              <T.ItensMenu>{renderMenuWithoutCustomItems()}</T.ItensMenu>
            </>
          )
        : itensMenu && (
            <>
              <T.ItensMobile
                show={showMenu}
                isPardini={style.variables.child_profiles?.length > 0}
              >
                {renderMenu()}
              </T.ItensMobile>
              <T.ItensMenu>{renderMenu()}</T.ItensMenu>
            </>
          )}
      {style.variables.child_profiles?.length > 0 && (
        <T.ContentLogoComunication show={showMenu}>
          <GroupPardiniComunication />
        </T.ContentLogoComunication>
      )}
    </T.ContainerMenu>
  );
};

export default Menu;
