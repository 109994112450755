import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getPardiniMenu: ["id"],
  getPardiniMenuSuccess: ["payload", "isLoading"],
  getPardiniMenuFail: ["isLoading", "error"],

  getPardiniDetails: ["id", "idDetails", "kindMenu"],
  getPardiniDetailsSuccess: ["payload", "isLoading"],
  getPardiniDetailsFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  error: [],
  isLoading: true,
  subMenuList: [],
  listMenuGroupPardini: [],
  detailsPageGroupPardini: {},
};

const getPardiniMenu = (state) => ({ ...state, isLoading: true });

const getPardiniMenuSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getPardiniMenuFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const getPardiniDetails = (state) => ({ ...state, isLoading: true });

const getPardiniDetailsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getPardiniDetailsFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PARDINI_MENU]: getPardiniMenu,
  [Types.GET_PARDINI_MENU_SUCCESS]: getPardiniMenuSuccess,
  [Types.GET_PARDINI_MENU_FAIL]: getPardiniMenuFail,

  [Types.GET_PARDINI_DETAILS]: getPardiniDetails,
  [Types.GET_PARDINI_DETAILS_SUCCESS]: getPardiniDetailsSuccess,
  [Types.GET_PARDINI_DETAILS_FAIL]: getPardiniDetailsFail,
});
