import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import { style } from "style";
import { Text } from "../SidebarStyle";

export const DescriptionText = styled("div")(
  css({
    margin: 0,
    transition: 0.2,
    visibility: "hidden",
    borderTop: "0.5px solid",
    borderColor: "grayMedium",

    p: {
      height: 0,
      margin: 0,
      padding: 0,
      color: "gray",
      display: "flex",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "text",
    },

    a: {
      height: 0,
      margin: 0,
      padding: 0,
      color: "primaryColor",
      display: "flex",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "24px",
      fontFamily: "text",
    },
  }),
);

export const ContainerListEvent = styled("div")(
  css({
    width: "100%",
    padding: "16px",
    borderRadius: "16px",
    backgroundColor: "paleGrey",

    ".collapse-trigger": {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: "auto",
      transition: "all 0.4s",
      transform: "rotate(-90deg)",
      path: {
        stroke: "black",
      },
    },
    "&.uncollapsed": {
      ".collapse-trigger": {
        transform: "rotate(90deg)",
      },
      ".collapse-element": {
        marginTop: "8px",
        visibility: "visible",

        a: {
          height: "auto",
          paddingTop: "4px",
        },

        p: {
          height: "auto",
          paddingTop: "4px",
        },
      },
    },
  }),
  ({ collapsed }) =>
    collapsed &&
    css({
      cursor: "pointer",
    }),
);

export const SideContainerLeft = styled("div")(
  css({
    width: "5px",
    marginRight: "8px",
    borderRadius: "4px",
    backgroundColor: "secondaryColor",
  }),
);

export const ContainerBodyList = styled("div")(
  css({
    display: "flex",
    padding: "2px 0px",
  }),
);

export const DateEvent = styled(Text)(
  css({
    margin: 0,
    fontSize: 13,
    color: "#8C91A9",
    marginBottom: "4px",
  }),
);

export const Event = styled(Text)(
  css({
    margin: 0,
    fontSize: 16,
    marginBottom: "1px",
    color: "secondaryColor",
  }),
);

export const HourEvent = styled(DateEvent)(
  css({
    margin: 0,
    color: "gray",
  }),
);

export const FooterEffectScroll = styled("div")(
  css({
    zIndex: 100,
    width: "100%",
    height: "1px",
    background: "defaultBackground",
    boxShadow: `0px -5px 20px 13px ${theme.colors.defaultBackground}`,
  }),
);

export const ContainerNoNextEvents = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    svg: {
      height: "80px",
      ".cls-1": {
        fill: style.colors.primaryColor,
        stroke: "none",
      },
      ".cls-2": {
        fill: "#ffc466",
        stroke: style.colors.primaryColor,
      },
      ".cls-3": {
        fill: "none",
        stroke: style.colors.primaryColor,
      },
    },
    img: {
      height: "80px",
    },
  }),
);
