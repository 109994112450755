/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { style } from "style";
import urls from "utils/constants/urls";
import { useAnalytics } from "utils/hooks";
import { manageClasses } from "helpers/sharedHelpers";
import { Creators as TeamActions } from "store/ducks/team";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { RadioButtonGeneric } from "components/core/Form/Form";
import SearchGeneric from "components/presentation/SearchGeneric";
import { PageTitle } from "components/core/Typography/Typography";

import { ReactComponent as BackIcon } from "assets/img/icons/backIcon.svg";
import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";
import { ReactComponent as FilterIcon } from "assets/img/icons/filterIcon.svg";
import SkeletonTeam from "./SkeletonTeam";

import * as S from "./TeamStyle";

useAnalytics("screen_team_list");

const Team = () => {
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "" ;
  const memberListRef = useRef();
  const [filterTeam, SetfilterTeam] = useState([]);
  const [filterUser, SetfilterUser] = useState([]);
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [contextSearchTeam, SetContextSearchTeam] = useState("");
  const [contextSearchUsers, SetContextSearchUsers] = useState("");
  const { allTeams, isLoading, selectedTeam } = useSelector(
    (state) => state.team,
  );
  const dispatch = useDispatch();
  const dispatchGetAllTeams = useCallback(
    () => dispatch(TeamActions.getAllTeams()),
    [dispatch],
  );
  const dispatchGetAllBrandFilter = useCallback(
    (nameTeam) => dispatch(TeamActions.getAllBrandFilter(nameTeam)),
    [dispatch],
  );
  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  useEffect(() => {
    if (!selectedTeam) {
      if (style.variables.child_profiles?.length > 0) {
        dispatchGetAllBrandFilter(style.names.nameApp);
      } else {
        dispatchGetAllTeams();
      }
    }

    if (selectedTeam?.teamMembers) {
      SetfilterUser(selectedTeam.teamMembers);
    } else {
      SetfilterUser([]);
    }
  }, [selectedTeam, dispatchGetAllTeams, dispatchGetAllBrandFilter]);

  useEffect(() => {
    SetfilterTeam(allTeams);
  }, [allTeams]);

  const selectTeam = (team) => {
    setShowTeamMembers(true);
    SetContextSearchUsers("");
    dispatch(TeamActions.selectTeam(team));
    memberListRef.current.scrollTop = 0;
  };

  const renderName = (name) => {
    const first = name[0];
    const last = name.slice(-1)[0];

    return first === last ? first : `${first} ${last}`;
  };

  const filterGeneric = ({ type, value }) => {
    if (type === "team") {
      SetContextSearchTeam(value);
      if (value.length > 0) {
        const filterTeams = allTeams?.filter((team) => {
          const filterUsers = team.teamMembers?.filter((user) =>
            user.name?.toLowerCase().includes(value.toLowerCase()),
          );

          return (
            team.teamName?.toLowerCase().includes(value.toLowerCase()) ||
            filterUsers.length > 0
          );
        });
        SetfilterTeam(filterTeams);
      } else {
        SetfilterTeam(allTeams);
      }
    } else if (type === "user") {
      SetContextSearchUsers(value);
      if (value.length > 0) {
        const filterUsers = selectedTeam?.teamMembers?.filter((user) =>
          user.name?.toLowerCase().includes(value.toLowerCase()),
        );
        SetfilterUser(filterUsers);
      } else {
        SetfilterUser(selectedTeam.teamMembers);
      }
    }
  };

  const handleSelectedBrandName = (value) => {
    dispatchGetAllBrandFilter(value);
    setSelectedBrandName(value);
  };

  const popoverEditComment = () => (
    <S.ContainerPopoverStyle>
      <S.BodyPopoveActions>
        <PageTitle title="Qual marca você deseja visualizar?" fontSize={2} />
        <S.BodyPopoveAlternative>
          <RadioButtonGeneric
            optionSelected={selectedBrandName}
            options={style.variables.child_profiles}
            onChange={(e) => handleSelectedBrandName(e)}
          />
        </S.BodyPopoveAlternative>
      </S.BodyPopoveActions>
    </S.ContainerPopoverStyle>
  );

  return (
    <Portal>
      <PageTitle
        title={
          isDesktop
            ? `${pageTitle || intl.messages["menu.team"]} ${
                showTeamMembers ? selectedTeam.teamName : " "
              }`
            : showTeamMembers
            ? selectedTeam?.teamName
            : pageTitle || intl.messages["menu.team"]
        }
      >
        {style.variables.child_profiles?.length > 0 && (
          <S.ContainerFilterTrigger>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="bottom"
              overlay={popoverEditComment()}
            >
              <S.ContainerFilterBrand>
                <FilterIcon />
              </S.ContainerFilterBrand>
            </OverlayTrigger>
          </S.ContainerFilterTrigger>
        )}
        <S.BtnOutTeam teamMembersShow={showTeamMembers}>
          <BackIcon onClick={() => setShowTeamMembers(false)} />
        </S.BtnOutTeam>
      </PageTitle>
      {isLoading ? (
        <SkeletonTeam />
      ) : (
        allTeams && (
          <S.ContentTeamMembersList>
            <S.TeamContainer teamMembersShow={showTeamMembers}>
              <S.ContainerSearch teamMembersShow>
                <SearchGeneric
                  placeholder="Pesquisar por time ou usuário"
                  valueString={contextSearchTeam}
                  onChange={(e) =>
                    filterGeneric({ type: "team", value: e.target.value })
                  }
                  onClick={() => filterGeneric({ type: "team", value: "" })}
                />
              </S.ContainerSearch>
              <S.ContainerTeams>
                {filterTeam.map(({ teamId, teamName, teamMembers }) => {
                  const limitedMembers = teamMembers.slice(0, 3);
                  return (
                    <S.TeamItemStyle
                      key={teamId}
                      variant={manageClasses({
                        selected: teamId === selectedTeam?.teamId,
                      })}
                      onClick={() => {
                        selectTeam({ teamId, teamName, teamMembers });
                        memberListRef.current.scrollTop = 0;
                      }}
                    >
                      <Flex flexDirection="column">
                        <S.TeamName title={teamName}>{teamName}</S.TeamName>
                        <S.CountMembers>{`${teamMembers.length} ${intl.messages["general.textPage.people"]}`}</S.CountMembers>
                      </Flex>
                      <Flex>
                        {teamMembers &&
                          limitedMembers.map(({ id, image }, index) => (
                            <Avatar
                              key={id}
                              border="3px solid #f4f6fb"
                              url={image}
                              ml={index === 0 ? `2px` : `-12px`}
                            />
                          ))}
                        <ArrowRight />
                      </Flex>
                    </S.TeamItemStyle>
                  );
                })}
              </S.ContainerTeams>
            </S.TeamContainer>

            <S.MembersList teamMembersShow={showTeamMembers}>
              {(filterUser?.length > 0 || contextSearchUsers.length > 0) && (
                <S.ContainerSearch teamMembersShow={showTeamMembers}>
                  <SearchGeneric
                    placeholder="Pesquisar por nome do usuário"
                    valueString={contextSearchUsers}
                    onChange={(e) =>
                      filterGeneric({ type: "user", value: e.target.value })
                    }
                    onClick={() => filterGeneric({ type: "user", value: "" })}
                  />
                </S.ContainerSearch>
              )}
              <S.ContainerMembers
                ref={memberListRef}
                teamMembersShow={showTeamMembers}
              >
                {filterUser?.map(({ id, name, image }) => {
                  const arrayName = name.toLowerCase().split(" ");
                  return (
                    <S.TeamMember
                      title={
                        intl.messages["general.textPage.clickOfAcessProfile"]
                      }
                      to={
                        {
                          pathname: `${urls.ROUTES.PROFILE}/${id}`,
                          state: { pageTitle },
                        }
                      }
                      key={id}
                    >
                      <S.ContainerAvatarTeam>
                        <Avatar url={image} size="100%" />
                      </S.ContainerAvatarTeam>
                      <S.MemberName title={renderName(arrayName)}>
                        {renderName(arrayName)}
                      </S.MemberName>
                    </S.TeamMember>
                  );
                })}
              </S.ContainerMembers>
            </S.MembersList>
          </S.ContentTeamMembersList>
        )
      )}
    </Portal>
  );
};

export default Team;
