/* eslint-disable react/prop-types */
import React from "react";

import { Tags, Unit } from "./TagsStyle";

const TagsCore = ({ tags }) => {
  const totalTags = tags.length;

  return (
    <Tags>
      {tags.map((tag, index) => (
        <React.Fragment key={tag.id}>
          <Unit>{tag.name}</Unit>
          {totalTags > index + 1}
        </React.Fragment>
      ))}
    </Tags>
  );
};

export default TagsCore;
