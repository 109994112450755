import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  calendarEvents: ["payload"],
  getCalendarEventsSuccess: ["payload", "isLoading"],
  getCalendarEventsFail: ["isLoading", "error"],

  getNextEvents: [],
  getNextEventsSuccess: ["payload", "isLoading"],
  getNextEventsFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  isLoading: true,
  monthCalender: "",
};
const calendarEvents = (state) => ({ ...state });

const getCalendarEventsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getCalendarEventsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getNextEvents = (state) => ({ ...state });

const getNextEventsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNextEventsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});
export default createReducer(INITIAL_STATE, {
  [Types.CALENDAR_EVENTS]: calendarEvents,
  [Types.GET_CALENDAR_EVENTS_SUCCESS]: getCalendarEventsSuccess,
  [Types.GET_CALENDAR_EVENTS_FAIL]: getCalendarEventsFail,

  [Types.GET_NEXT_EVENTS]: getNextEvents,
  [Types.GET_NEXT_EVENTS_SUCCESS]: getNextEventsSuccess,
  [Types.GET_NEXT_EVENTS_FAIL]: getNextEventsFail,
});
