/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import { POST, GET } from "utils/constants/verbs";
import { takeLatest, put } from "redux-saga/effects";
import {
  AUTH_USER,
  RESET_PASSWORD,
  POST_USER,
  POSTS_PUBLICATIONS,
} from "utils/constants/endpoints";
import urls from "utils/constants/urls";
import { style } from "style";
import { requestAPI } from "helpers/requestHelpers";
import { history } from "helpers/sharedHelpers";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { Types } from "../ducks/auth";

function* requestAuth({ payload }) {
  const body = {
    username: payload.email,
    password: payload.password,
  };
  try {
    if (
      style.names.nameApp === "Pintou Parceria Suvinil" &&
      !payload.recaptchaSuccess
    ) {
      yield put({
        type: AlertTypes.SET_ALERTS_REQUEST,
        messages: {
          type: "errors",
          title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
          msg: 'Clique em "Não sou um robô" para continuar',
          buttons: [
            {
              title: "OK",
              event: () => window.location.reload(urls.ROUTES.LOGIN),
            },
          ],
        },
      });
      return;
    }
    yield axios({
      method: "post",
      url: AUTH_USER,
      headers: {
        "Content-Type": "application/json",
        "access-token": undefined,
        "host-header": style.api.hostHeader,
      },
      data: body,
    })
      .then((response) => {
        const { headers } = response;
        const idUser = response.data.id;
        const auto_declared = response.data?.auto_declared;
        localStorage.setItem("access-token", headers["access-token"]);
        localStorage.setItem("uid", headers.uid);
        localStorage.setItem("client", headers.client);
        localStorage.setItem("resource-type", headers["resource-type"]);
        localStorage.setItem("host-header", style.api.hostHeader);
        localStorage.setItem("idUser", idUser);
        localStorage.setItem("auto_declared", auto_declared);
        localStorage.setItem("headers", JSON.stringify(headers));
      })
      .catch((error) => {
        throw error;
      });
    yield put({ type: Types.GET_TERMS_OF_USE });
    yield put({
      type: Types.USER_AUTH_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
      buttons: [
        {
          title: "OK",
          event: () => window.location.reload(urls.ROUTES.LOGIN),
        },
      ],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.USER_AUTH_FAIL,
      error,
    });
  }
}

function* forgotThePassword({ payload }) {
  const body = { email: payload.email };
  try {
    const response = yield requestAPI({
      verb: POST,
      endPoint: RESET_PASSWORD,
      data: body,
    });

    yield put({
      type: Types.FORGOT_THE_PASSWORD_SUCCESS,
    });

    const messages = {
      type: "success",
      title: <FormattedMessage id="modal.textPage.successModal" />,
      msg: "Por favor verifique seu e-mail com as instruções.",
    };

    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });

    history.push(urls.ROUTES.LOGIN);
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: "Entre em contato com o administrador",
      },
    });
    yield put({
      type: Types.FORGOT_THE_PASSWORD_FAIL,
      ...error.response.data,
    });
  }
}

function* postCreateUserRequest({ payload }) {
  const formData = new FormData();
  const entries = Object.entries(payload);

  // eslint-disable-next-line array-callback-return
  entries.map((item) => {
    if (item[0] === "email") {
      formData.append("[contacts_attributes][][contact]", item[1]);
      formData.append("[contacts_attributes][][kind]", "email");

      item[0] = "sendemail";
    }
    if (item[0] === "cpf") {
      item[0] = "email";
    }
    if (item[0] === "phone") {
      formData.append("[contacts_attributes][][contact]", item[1]);
      formData.append("[contacts_attributes][][kind]", "phone");
    }
    if (
      item[0] === "neighborhood" ||
      item[0] === "street" ||
      item[0] === "complement" ||
      item[0] === "number" ||
      item[0] === "zip_code" ||
      item[0] === "city" ||
      item[0] === "uf"
    ) {
      item[0] = `address[${item[0]}]`;
    }
    // if (item[1] && typeof item[1] !== "string") {
    // item[1].map((values) => formData.append(`${item[0]}[]`, values.id));
    // }
    if (
      item[0] !== "login" &&
      item[0] !== "phone" &&
      (typeof item[1] === "string" || typeof item[1] === "number")
    ) {
      formData.append(item[0], item[1]);
    }
  });

  // formData.append("roles[]", "normal_user");

  try {
    yield requestAPI({
      verb: POST,
      endPoint: POST_USER,
      data: formData,
    });
    yield put({
      type: Types.POST_CREATE_USER_SUCCESS,
    });
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Cadastro realizado com sucesso",
        buttons: [
          {
            title: "OK",
            event: () => history.push(urls.ROUTES.LOGIN),
          },
        ],
      },
    });
    localStorage.setItem("uid", payload.login);
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data.errors,
      },
    });

    yield put({
      type: Types.POST_CREATE_USER_FAIL,
      ...error.response.data,
    });
  }
}

function* getExperienceTimes() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: "experience_times",
    });
    const listTimeExperience = response.map(({ id, description }) => ({
      id,
      value: id,
      text: description,
    }));
    yield put({
      type: Types.GET_EXPERIENCE_TIMES_SUCCESS,
      listTimeExperience,
    });
  } catch (error) {
    yield put({
      type: Types.GET_EXPERIENCE_TIMES_FAIL,
      ...error.response.data,
    });
  }
}

function* getTypeOfWorks() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: "type_of_works",
    });
    const listTypesWork = arrayTransforSelected(response);
    yield put({
      type: Types.GET_TYPE_OF_WORKS_SUCCESS,
      listTypesWork,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TYPE_OF_WORKS_FAIL,
      ...error.response.data,
    });
  }
}

function* getUserInterests() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: "user_interests",
    });
    const listUserInterests = arrayTransforSelected(response);
    yield put({
      type: Types.GET_USER_INTERESTS_SUCCESS,
      listUserInterests,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_INTERESTS_FAIL,
      ...error.response.data,
    });
  }
}

function* getUserTrainings() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: "trainings",
    });
    const listUserTrainings = arrayTransforSelected(response);
    yield put({
      type: Types.GET_USER_TRAININGS_SUCCESS,
      listUserTrainings,
    });
  } catch (error) {
    yield put({
      type: Types.GET_USER_TRAININGS_FAIL,
      ...error.response.data,
    });
  }
}

function* getStateList() {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: "/states",
    });

    const listState = response.map(({ id, name, uf }) => ({
      id,
      value: id,
      text: name,
      uf,
    }));

    yield put({
      type: Types.GET_STATE_LIST_SUCCESS,
      listState,
    });
  } catch (error) {
    yield put({
      type: Types.GET_STATE_LIST_FAIL,
      ...error.response.data,
    });
  }
}

function* getCitiesList({ payload }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: `/states/${payload.id}/cities`,
    });
    const listCities = response.map(({ id, name }) => ({
      id,
      value: id,
      text: name,
    }));
    yield put({
      type: Types.GET_CITIES_LIST_SUCCESS,
      listCities,
    });
  } catch (error) {
    yield put({
      type: Types.GET_CITIES_LIST_FAIL,
      ...error.response.data,
    });
  }
}

function* getTermOfUse() {
  try {
    const { file } = yield requestAPI({
      verb: GET,
      endPoint: "terms",
    });

    yield put({
      type: Types.GET_TERM_OF_USE_SUCCESS,
      terms_url: file.url,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TERM_OF_USE_FAIL,
      ...error.response.data,
    });
  }
}

function* getLgpd() {
  try {
    const { file } = yield requestAPI({
      verb: GET,
      endPoint: "privacy_policies",
    });

    yield put({
      type: Types.GET_TERM_OF_USE_SUCCESS,
      lgpd_url: file.url,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TERM_OF_USE_FAIL,
      ...error.response.data,
    });
  }
}

function* getTermsOfUse() {
  try {
    const { file } = yield requestAPI({
      verb: GET,
      endPoint: "terms",
    });

    const auto_declared = localStorage.getItem("auto_declared");
    const host_header = localStorage.getItem("host-header");
    const redirect_after_login =
      host_header === "suvinil" &&
      (!auto_declared ||
        auto_declared === "null" ||
        auto_declared === "undefined")
        ? urls.ROUTES.RESEARCH_SUVINIL
        : urls.ROUTES.MURAL;

    const messages = {
      title: <FormattedMessage id="modal.textPage.termsOfUse" />,
      msg: <FormattedMessage id="modal.textPage.youAgreeToOurTermsOfUse" />,
      buttons: [
        {
          title: "Ver",
          variant: ["transparent"],
          event: () => window.open(`${file.url}`, "_blank"),
        },
        {
          title: "Concordo",
          event: () => window.location.assign(redirect_after_login),
        },
      ],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });

    yield put({
      type: Types.GET_TERMS_OF_USE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_TERMS_OF_USE_FAIL,
      ...error.response.data,
    });
  }
}

function* validateTeamsToken({ payload }) {
  const decodedToken = Buffer.from(payload.token, "base64").toString("ascii");
  const values = decodedToken.split("/");
  const [
    idUser,
    sessionIndex,
    accessToken,
    client,
    userEmail,
    resourceType,
    hostHeader,
  ] = values;

  try {
    if (values.length === 7) {
      const { data } = yield axios({
        url: `${POSTS_PUBLICATIONS}?page=1`,
        method: GET,
        headers: {
          "access-token": accessToken,
          client,
          uid: userEmail,
          "resource-type": resourceType,
          "host-header": hostHeader,
        },
      });

      if (data) {
        localStorage.setItem("idUser", idUser);
        localStorage.setItem("sessionIndex", sessionIndex);
        localStorage.setItem("access-token", accessToken);
        localStorage.setItem("client", client);
        localStorage.setItem("uid", userEmail);
        localStorage.setItem("nameID", userEmail);
        localStorage.setItem("resource-type", resourceType);
        localStorage.setItem("host-header", hostHeader);

        window.location.reload();
      }
    } else {
      throw new Error();
    }
  } catch {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="login.textPage.invalidToken" />,
        msg: "Por favor, gere um novo token de autenticação e certifique-se de que o conteúdo foi copiado devidamente.",
      },
    });
  }
}

const arrayTransforSelected = (arrayList) =>
  arrayList.map(({ id, name }) => ({ id, value: name, text: name }));

export function* watcherSaga() {
  yield takeLatest(Types.USER_AUTH_REQUEST, requestAuth);
  yield takeLatest(Types.FORGOT_THE_PASSWORD, forgotThePassword);
  yield takeLatest(Types.POST_CREATE_USER_REQUEST, postCreateUserRequest);
  yield takeLatest(Types.GET_EXPERIENCE_TIMES, getExperienceTimes);
  yield takeLatest(Types.GET_TYPE_OF_WORKS, getTypeOfWorks);
  yield takeLatest(Types.GET_USER_INTERESTS, getUserInterests);
  yield takeLatest(Types.GET_USER_TRAININGS, getUserTrainings);
  yield takeLatest(Types.GET_STATE_LIST, getStateList);
  yield takeLatest(Types.GET_CITIES_LIST, getCitiesList);
  yield takeLatest(Types.GET_TERM_OF_USE, getTermOfUse);
  yield takeLatest(Types.GET_LGPD, getLgpd);
  yield takeLatest(Types.GET_TERMS_OF_USE, getTermsOfUse);
  yield takeLatest(Types.VALIDATE_TEAMS_TOKEN, validateTeamsToken);
}
