/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

const BirthdaysSkeleton = () => {
  const skeletonRepeat = 8;

  return (
    <Flex
      p={4}
      width="fit-content"
      height="100%"
      flexDirection="column"
      spaceChildren={3}
      borderRight="1px solid #ccd2e0"
    >
      {[...Array(skeletonRepeat)].map((_val, index) => (
        <Skeleton key={index} duration={0.8} width={342} height={68} />
      ))}
    </Flex>
  );
};

export default BirthdaysSkeleton;
