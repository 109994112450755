import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const Notification = styled(Flex)(
  css({
    transition: "1s",
    padding: "16px 16px 10px 16px",
    borderBottom: `0.5px solid ${theme.colors.grayDefaultColor}`,
    a: {
      textDecoration: "none",
    },
    ":hover": {
      backgroundColor: "grayLight",
      cursor: "pointer",
    },
    "@media (min-width: 1024px)": {
      padding: "16px 16px 0 16px",
    },
  }),
);

export const NotificationContainer = styled("div")(
  css({
    display: "flex",
    alignItems: "center"
  }),
)

export const NotificationCircle = styled("div")(
  css({
    width: 10,
    height: 10,
    marginLeft: 3,
    marginBottom: 2,
    borderRadius: 40,
    border: "4px solid rgba(80, 31, 153, 0.1)",
    boxShadow: "0px 0px 1px 4px rgba(0, 0, 0, 0.2)",
    backgroundColor: `${theme.colors.primaryColor}`,
  }),
);

export const NotificationTitle = styled("h3")(
  css({
    fontSize: 3,
    color: "charcoalGrey",
    fontFamily: "textLight",
  }),
);

export const NotificationDescription = styled("h2")(
  css({
    fontSize: 2,
    color: "labelColor",
    fontFamily: "text",
  }),
);

export const NotificationDate = styled("h4")(
  css({
    fontSize: 12,
    marginBottom: 2,
    fontFamily: "text",
    color: "labelColor",
    textTransform: "uppercase",
  }),
);
