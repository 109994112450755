import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "react-router-dom";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const BoxConfig = styled(Flex)(
  css({
    p: 12,
    mb: 12,
    borderRadius: "20px",
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "24px",
    flexDirection: "column",
    display: "flex",
    backgroundColor: `${theme.colors.focusInput}`,
    "@media (min-width: 768px)": {
      display: "none",
    },
  }),
);

export const AvatarContainer = styled("div")(
  css({
    display: "flex",
    width: "52px",
    alignItems: "center",
  }),
);

export const TextContainer = styled("div")(
  css({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0 20px 0px 0px",
    wordWrap: "break-word",
  }),
);

export const UserName = styled("h3")(
  css({
    fontSize: "18px",
    fontFamily: "text",
    fontWeight: "500",
    lineHeight: "24px",
    marginBottom: "5px",
    color: "#333947",
  }),
);

export const ContainerNotProductsFound = styled(Flex)(
  css({
    mb: 12,
    mt: 100,
    alignItems: "center",
    justifyItems: "center",
  }),
);

export const ImgProduct = styled("img")(
  css({
    height: 118,
    maxWidth: 130,
  }),
);

export const NameProduct = styled("h3")(
  css({
    fontSize: 16,
    maxWidth: 96,
    color: "gray",
    maxHeight: 36,
    lineHeight: "18px",
    overflow: "hidden",
    margin: "12px auto",
    fontFamily: "heading",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);

export const Price = styled(Flex)(
  css({
    minWidth: 110,
    borderRadius: 60,
    padding: "8px 12px",
    alignItems: "center",
    margin: "-62px 19px",
    position: "absolute",
    justifyContent: "center",
    backgroundColor: "yellow",
  }),
);

export const OutOfStock = styled(Price)(
  css({
    width: "auto",
    margin: "-62px 18px",
    backgroundColor: "grayMedium",
  }),
);

export const Product = styled(Link)(
  ({ outOfStock }) =>
    outOfStock &&
    css({
      "img, h3": {
        opacity: 0.5,
      },
    }),
);

export const ProductInfo = styled("div")(
  css({
    width: 148,
    height: 210,
    borderRadius: 8,
    padding: "10px",
    textAlign: "center",
    marginBottom: 40,
    backgroundColor: "paleGrey",
    ":hover": {
      h3: {
        color: "#000 !important",
      },
      cursor: "pointer",
      border: "1px solid white",
      boxShadow: "0px 12px 22px rgba(0, 0, 0, 0.12)",
    },
  }),
);

export const ProductList = styled(Flex)(
  css({
    flexWrap: "wrap",
    alignItems: "left",
    justifyItems: "left",
    marginBottom: "50px",
    paddingLeft: "10px",
    a: {
      textDecoration: "none",
    },

    "@media (max-width: 340px)": {
      paddingLeft: "70px",
    },
    "@media (min-width: 341px) and (max-width: 372px)": {
      paddingLeft: "100px",
    },
    "@media (min-width: 400px) and (max-width: 500px)": {
      paddingLeft: "50px",
    },
    "@media (min-width: 500px) and (max-width: 543px)": {
      paddingLeft: "100px",
    },
  }),
);

export const Text = styled("p")(
  css({
    whiteSpace: "normal",
    fontFamily: "text",
    color: "backText",
    fontSize: "18px",
    fontWeight: "600",
    margin: 0,
  }),
);

export const ValuePrice = styled("div")(
  css({
    color: "white",
    fontFamily: "text",
    fontSize: 16,
  }),
  ({ sidebar }) =>
    sidebar &&
    css({
      color: "yellow",
    }),
);
