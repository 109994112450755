import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const BodyFiles = styled(Flex)(
  css({
    height: "100%",
    width: "100%",
    marginBottom: "120px",
    alignItems: "center",
    justifyItems: "center",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (max-width: 342px)": {
      paddingLeft: "80px",
    },

    "@media (min-width: 366px) and (max-width: 386px)": {
      paddingLeft: "40px",
    },
    "@media (min-width: 386px) and (max-width: 490px)": {
      paddingLeft: "60px",
    },
  }),
);

export const ContainerNotFilesFound = styled(Flex)(
  css({
    marginTop: "100px",
    alignItems: "center",
    justifyItems: "center",
    svg: {
      g: {
        g: {
          rect: {
            fill: theme.colors.primaryColor,
          },
        },
      },
      defs: {
        linearGradient: {
          stop: {
            ":first-of-type": {
              stopColor: theme.colors.primaryColor,
            },
            ":last-of-type": {
              stopColor: theme.colors.secondaryColor,
            },
          },
        },
      },
    },
  }),
);

export const Folder = styled("div")(
  css({
    width: "125px",
    display: "flex",
    height: "125px",
    alignItems: "center",
    marginBottom: "15px",
    borderRadius: "9.6px",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: theme.colors.paleGrey,
    ":hover": {
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    "@media (max-width: 320px)": {
      width: "120px",
      height: "120px",
    },
    svg: {
      "#primary_stop_color": {
        stopColor: theme.colors.primaryColor,
      },
      "#secondary_stop_color": {
        stopColor: theme.colors.secondaryColor,
      },
    },
  }),
);

export const FolderName = styled("h3")(
  css({
    margin: "0 0 16px 0",
    fontSize: 2,
    height: "70px",
    width: "120px",
    fontFamily: "text",
    lineHeight: "24px",
    overflow: "hidden",
    textAlign: "center",
    color: "charcoalGrey",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }),
);

export const FolderPath = styled("div")(
  css({
    height: "32px",
    display: "flex",
    padding: "15px",
    alignItems: "center",
    justifyItems: "center",
    backgroundColor: theme.colors.paleGrey,
  }),
);

export const ItemFolder = styled(Flex)(
  css({
    justifyItems: "left",
    borderBottom: `1px solid ${theme.colors.paleGrey}`,
    ":first-of-type": {
      borderTop: `1px solid ${theme.colors.paleGrey}`,
    },
  }),
);

export const LinkFile = styled("a")(
  css({
    textDecoration: "none !important",
    "#Rectangle_Copy_5": {
      fill: theme.colors.primaryColor,
    },
  }),
);

export const WordSearched = styled("h4")(
  css({
    mb: 2,
    fontSize: 2,
    color: "gray",
    display: "block",
    fontFamily: `${theme.fonts.textItalic}`,
  }),
);

export const CircleSearched = styled("div")(
  css({
    mr: 2,
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: `${theme.colors.secondaryColor}`,
  }),
);

export const SubTitle = styled("h2")(
  css({
    fontSize: 18,
    color: "backText",
    fontFamily: "textLight",
    wordBreak: "break-all",
  }),
);

export const NameDetails = styled(SubTitle)(
  css({
    m: 0,
    fontSize: 1,
    color: "gray",
  }),
);

export const BoxButton = styled("div")(
  css({
    height: "70px",
    display: "flex",
    alignItems: "center",

    button: {
      margin: "0 10px",
    },
  }),
);
