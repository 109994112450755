import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  guestAuthRequest: ["payload"],
  guestAuthSuccess: ["payload", "isLoading", "isAuthenticated"],
  guestAuthFailure: ["error", "isLoading"],
  guestAuthInvalidate: ["isAuthenticated"],
});

const INITIAL_STATE = {
  email: "",
  password: "",
  isLoading: false,
  isAuthenticated: false,
};

const guestAuthRequest = () => ({ ...INITIAL_STATE, isLoading: true });

const guestAuthSuccess = (state) => ({
  ...state,
  isLoading: false,
  isAuthenticated: true,
});

const guestAuthFailure = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const guestAuthInvalidate = (state) => ({
  ...state,
  isAuthenticated: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GUEST_AUTH_REQUEST]: guestAuthRequest,
  [Types.GUEST_AUTH_SUCCESS]: guestAuthSuccess,
  [Types.GUEST_AUTH_FAILURE]: guestAuthFailure,
  [Types.GUEST_AUTH_INVALIDATE]: guestAuthInvalidate,
});
