import styled from "styled-components";
import theme from "styles/theme";
import css from "@styled-system/css";
import Modal from "styled-react-modal";

import Flex from "components/core/Grid/Flex";

const progressColorModifiers = {
  available: css({ color: theme.colors.secondaryColor }),
  initiated: css({ color: "blue" }),
  finished: css({ color: "greenDark" }),
};

export const Subtitles = styled(Flex)(
  css({
    alignItems: "center",
    mb: 4,
    flexWrap: "wrap",
    h3: {
      fontSize: 2,
      color: "grayDark",
      margin: 0,
      marginRight: 3,
    },
  }),
);

export const Status = styled("div")(
  ({ bg }) =>
    css({
      "&::before": {
        content: "''",
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        position: "absolute",
        left: 0,
        top: "4px",
        bg,
      },
    }),
  css({
    position: "relative",
    color: "backText",
    paddingLeft: 4,
    "&:not(:last-child)": {
      marginRight: 4,
    },
  }),
);

export const CoursesList = styled(Flex)(
  css({
    flexWrap: "wrap",
    mx: -1,
    "> :nth-child(n)": {
      mx: 1,
      mb: 4,
    },

    "@media (max-width: 320px)": {
      marginBottom: "50px",
      marginLeft: "25px",
    },

    "@media (min-width: 321px) and (max-width: 374px)": {
      marginLeft: "35px",
    },

    "@media (min-width: 375px) and (max-width: 410px)": {
      marginLeft: "45px",
    },

    "@media (min-width: 411px) and (max-width: 767px)": {
      marginLeft: "55px",
    },
  }),
);

export const CourseCard = styled(Flex)(
  css({
    bg: "paleGrey",
    cursor: "pointer",
    borderRadius: "8px",
    flexDirection: "column",
    width: "228px",
    height: "296px",
    wordWrap: "break-word",
    overflow: "hidden",
    transition: "box-shadow ease-out .2s",
    "&:hover": {
      boxShadow: "0px 12px 22px rgba(0, 0, 0, 0.12);",
      "> div": {
        borderColor: "white",
      },
    },
    "@media(max-width: 400px)": {
      height: "auto",
    },
    img: {
      borderRadius: "5px 5px 0 0",
      height: "106px",
      objectFit: "cover",
      width: "100%",
      "@media(max-width: 400px)": {
        height: "auto",
      },
    },
  }),
);

export const CourseInfo = styled(Flex)(
  css({
    flex: 1,
    padding: 3,
    width: "228px",
    borderRadius: "5px",
    flexDirection: "column",
    borderWidth: "0 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "transparent",
    h1: {
      mb: 0,
      fontSize: 2,
      lineHeight: 1.4,
      fontWeight: "600",
      fontFamily: "heading",
      color: "#4d556a",
    },
    h2: {
      mt: 1,
      mb: 2,
      fontSize: 1,
      lineHeight: 1.4,
      fontFamily: "text",
    },
    p: {
      mb: 0,
      fontSize: 1,
      color: "gray",
      lineHeight: 1.6,
      fontFamily: "text",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
    },
  }),
);

export const ShadowItem = styled(Flex)(
  css({
    flex: "0 0 228px",
  }),
);

export const ContentPagetitle = styled("div")(
  css({
    "@media (max-width: 320px)": {
      width: "190px",
    },
  }),
);

export const ContainerNoResults = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "> svg": {
      size: "60%",
    },
  }),
);

export const ContainerTittle = styled("div")(
  css({
    width: "80%",
    fontSize: "17px",
    lineHeight: "20px",
    color: "textConfig",
    marginTop: "30px",
  }),
);

export const ModulesCount = styled("h2")(
  ({ status }) => progressColorModifiers[status],
);

export const FormInput = styled("div")(
  css({
    mb: 5,
    textTransform: "uppercase",
    width: "100%",
    textAlign: "left",
  }),
);

export const StyledModal = Modal.styled(
  css({
    p: 30,
    width: 570,
    fontSize: 18,
    minHeight: 240,
    borderRadius: 5,
    display: "flex",
    fontFamily: "text",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#fafafa",
  }),
  ({ type }) =>
    type
      ? css({ borderTop: "none" })
      : css({ borderTop: `5px solid ${theme.colors.primaryColor}` }),
);

export const TitleModal = styled("p")(
  css({
    fontSize: 36,
    fontWeight: "bold",
    color: "blackLight",
    fontFamily: "heading",
  }),
);

export const SubTitleModal = styled("h2")(
  css({
    mb: 4,
    fontSize: 24,
    lineHeight: 1.67,
    fontWeight: 600,
    color: "secondaryColor",
    fontFamily: "textLight",
  }),
);

export const ContainerModal = styled(Flex)(
  css({
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }),
);

export const ContainerType = styled("div")(
  css({
    width: 75,
    height: 75,
    marginTop: -75,
    borderRadius: "100%",
  }),
  ({ type }) =>
    type === "success"
      ? css({
          backgroundColor: "green",
          boxShadow: `0 2px 4px 0 ${theme.colors.green}`,
        })
      : type === "errors" &&
        css({
          backgroundColor: "redLight",
          boxShadow: `0 2px 4px 0 ${theme.colors.redLight}`,
        }),
);

export const Form = styled("form")(
  css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    "&.error input": {
      borderBottomColor: "#EF0000",
    },
  }),
);

export const SuccessText = styled("div")(
  css({
    maxWidth: 325,
    margin: "20px auto 0",
    lineHeight: 1,
  }),
);

export const ErrorText = styled("p")(
  css({
    color: "#EF0000",
    textTransform: "initial",
    fontSize: 12,
    lineHeight: 1.33,
    mt: 1,
  }),
);
