/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { style } from "style";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";

import { Creators as AcademyActions } from "store/ducks/academy";

import { ReactComponent as IconCertificate } from "assets/img/icons/certificate.svg";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";
import { history } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import CertificateSkeleton from "./CertificateSkeleton";

import * as S from "./CertificateStyle";

const Certificate = () => {
  const { title, id } = useParams();
  const dispatch = useDispatch();
  const { course_details: course, isLoading } = useSelector(
    ({ academy }) => academy,
  );
  const location = useLocation();
  const { pageTitle } = location.state || "" ;

  useEffect(() => {
    isLoading && dispatch(AcademyActions.getDetailsCourse(id));
  }, [isLoading, dispatch, id]);
  return (
    <Portal>
      {isLoading ? (
        <CertificateSkeleton />
      ) : (
        <S.CertificateContainer>
          <S.CertificateKind>
            <IconCertificate />
            <h1>
              Parabéns!{" "}
              {!style.variables.child_profiles?.length > 0 && "Curso de"}{" "}
              {title} foi concluído.
            </h1>
            {style.variables.gamificationEnabled && (
              <>
                <h4>Ao concluir esse curso você ganhou:</h4>
                <h3>
                  {course.score} <IconCoins size={24} />
                </h3>
              </>
            )}

            <Flex mt={4} spaceChildren={3}>
              <Button
                name={`Ver outros ${
                  !style.variables.child_profiles?.length > 0 && "cursos"
                }`}
                variant="completedTransparent"
                action={() => history.push(`${urls.ROUTES.ACADEMY}`, {pageTitle})}
              />
              {course.has_certificate && (
                <Button
                  name="Baixar certificado"
                  variant="completed"
                  action={() =>
                    dispatch(AcademyActions.getCertificate({ id, title }))
                  }
                />
              )}
            </Flex>
          </S.CertificateKind>
        </S.CertificateContainer>
      )}
    </Portal>
  );
};

export default Certificate;
