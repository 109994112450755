
  export const style = {
    api: {
      apiProduction: "https://big.prd.appnoz.com.br/",
      newApiProduction: "null",
      samlUrl: null,
      nameProfile: "big",
      hostHeader: "big",
    },

    icons: {
      loginIcon: "https://s3.amazonaws.com/timcoo-geral/Logos/logo_big.png",
      menuIcon: localStorage.getItem("menuIcon") || "https://timcoo-geral.s3.amazonaws.com/Logos/secondary_logo_big.png",
      browserLogo: "https://s3.amazonaws.com/timcoo-geral/Logos/logo_big.png",
    },

    shareAttributes: {
      shareImage: "https://s3.amazonaws.com/timcoo-geral/Logos/logo_big.png",
      shareTitle: "Noz - Plataforma de comunicação",
      shareDescription: "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
    },
  
    names: {
      nameApp: localStorage.getItem("nameApp") || "big",
      nameScore: "Nozes",
      nameStore: "Loja",
      nameAcademy: localStorage.getItem("nameAcademy") || "Cursos",
      nameTeam: localStorage.getItem("nameTeam") || "Time",
      nameCalendar: localStorage.getItem("nameCalendar") || "Calendário",
      nameTools: localStorage.getItem("nameTools") || "Ferramentas",
    },
  
    colors: {
      primaryColor: localStorage.getItem("primaryColor") || "#FF7A00",
      secondaryColor: localStorage.getItem("secondaryColor") || "#FF500F",
      tertiaryColor: "",
    },

    variables: {
      gamificationEnabled: true,
      child_profiles: [],
      hasPrivacyPolicies: false,
      externalUse: false,
      customClient: true,
    },
  };
