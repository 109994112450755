import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";

const SkeletonFiles = () => {
  const itens = [];
  for (let i = 0; i < 16; i++) {
    itens.push(
      <Flex
        flexDirection="column"
        alignItems="center"
        spaceChildren={1}
        mb={4}
        padding={2}
      >
        <Skeleton duration={0.8} width={125} height={125} />
        <Skeleton duration={0.8} width={99} height={42} />
      </Flex>,
    );
  }
  return itens;
};

export default SkeletonFiles;
