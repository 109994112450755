import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const App = styled(Flex)(
  css({
    mb: 25,
    borderRadius: 8,
    alignItems: "center",
    flexDirection: "column",
    ":hover": {
      opacity: "0.7",
      cursor: "pointer",
    },
  }),
);

export const AppImg = styled("img")(
  css({
    mb: 2,
    height: 80,
    width: 132,
    borderRadius: 8,
    objectFit: "contain",
    backgroundColor: "paleGrey",
  }),
);

export const AppList = styled(Flex)(
  css({
    flexWrap: "wrap",
    alignItems: "left",
    justifyItems: "left",
    a: {
      textDecoration: "none",
    },
  }),
);

export const AppName = styled("h3")(
  css({
    width: 132,
    fontSize: 2,
    maxHeight: 36,
    overflow: "hidden",
    textAlign: "center",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    color: `${theme.colors.backText}`,
    fontFamily: `${theme.fonts.heading}`,
  }),
);

export const ContainerNoResultsApp = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "50px",
    svg: {
      size: "240px",
      ".cls-1": {
        fill: theme.colors.primaryColor,
        stroke: "none",
      },
      ".cls-2": {
        fill: "#ffc466",
        stroke: "none",
      },
    },
  }),
);

export const ContainerTitle = styled("div")(
  css({
    fontSize: "30px",
    lineHeight: "24px",
    color: "textConfig",
    marginTop: "50px",
  }),
);
