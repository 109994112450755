import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";

import Flex from "components/core/Grid/Flex";
import theme from "../../../styles/theme";

export const CourseDetail = styled(Flex)(
  css({
    flexDirection: "column",
    width: "590px",
    margin: "40px auto 50px auto",
    paddingBottom: "60px",
    "> h2": {
      fontFamily: "heading",
      fontWeight: "600",
      color: "backText",
      fontSize: 3,
    },
    button: {
      minWidth: "60px",
    },
    "@media (max-width: 590px)": {
      width: "100%",
    },
  }),
);

export const CourseHeader = styled("header")(
  css({
    display: "flex",
    flexDirection: "column",
    marginBottom: 5,
  }),
);

export const CourseCover = styled("img")(
  css({
    borderRadius: "8px 8px 0 0",
    width: "100%",
    height: "260px",
    objectFit: "cover",
  }),
);

export const CourseInfo = styled(Flex)(
  css({
    flexDirection: "column",
    bg: "paleGrey",
    minHeight: "191px",
    padding: 3,
  }),
);

export const CourseTitle = styled("h1")(
  css({
    fontSize: 3,
    marginBottom: 3,
    fontFamily: "heading",
  }),
  ({ completed, started }) =>
    css({
      // eslint-disable-next-line no-nested-ternary
      color: completed ? "greenDark" : started ? "blue" : "secondaryColor",
    }),
);

export const CourseDescription = styled("p")(
  css({
    fontFamily: "text",
    fontSize: 2,
    lineHeight: 1.4,
    color: "gray",
  }),
);

export const CourseModulesList = styled("ul")(
  css({
    listStyle: "none outside",
    padding: 0,
    margin: 0,
  }),
);

export const CourseModule = styled("li")(
  css({
    position: "relative",
    fontFamily: "text",
    fontSize: 2,
    color: "gray",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "60px",
    lineHeight: "53px",
    height: "30px",
    overflow: "hidden",
    transition: "all ease-out .3s",
    "&:not(:last-child)": {
      borderBottom: "1px solid",
      borderColor: "grayDefaultColor",
    },
    "&.uncollapsed": {
      overflow: "auto",
    },
    "&::before": {
      position: "absolute",
      content: "''",
      borderRadius: "50%",
      width: "10px",
      height: "11px",
      bg: "gray",
      top: "8px",
      left: "16px",
    },
    "> div > span": {
      position: "absolute",
      left: "-18px",
      top: "-13px",
      fontSize: 0,
      width: "16px",
      fontWeight: "600",
    },
    "> div > svg": {
      position: "absolute",
      top: "4px",
      right: "15px",
      marginLeft: "auto",
      transform: "rotate(-90deg)",
      path: {
        stroke: theme.colors.gray,
      },
    },
    "> div > h2": {
      paddingRight: "45px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }),
  variant({
    variants: {
      completed: {
        "&::before": {
          bg: "greenDark",
        },
      },
      inProgress: {
        "&::before": {
          bg: "blue",
        },
      },
    },
  }),
);

export const CourseModuleDetails = styled(Flex)(
  css({
    cursor: "pointer",
  }),
  variant({
    variants: {
      completed: {
        color: "greenDark",
      },
      inProgress: {
        color: "blue",
      },
    },
  }),
);

export const CourseModuleTitle = styled("h2")(
  css({
    margin: 0,
    fontSize: 2,
    fontFamily: "text",
    lineHeight: "28px",
    fontWeight: "100",
    marginBottom: "24px",
  }),
);

export const CourseTasksList = styled("ul")(
  css({
    listStyle: "none outside",
    marginTop: "-10px",
    padding: 0,
  }),
);

export const CourseTask = styled("li")(
  css({
    position: "relative",
    display: "flex",
    lineHeight: "32px",
    paddingLeft: "24px",
    "&::before": {
      position: "absolute",
      content: "''",
      borderRadius: "50%",
      width: "6px",
      height: "6px",
      bg: "gray",
      top: "13px",
      left: "-16px",
    },
    "> span": {
      position: "absolute",
      left: "0px",
      top: "5px",
      fontSize: 0,
      width: "20px",
    },
  }),
  variant({
    variants: {
      completedSub: {
        color: "greenDark",
        "&::before": {
          bg: "greenDark",
        },
      },
      inSubProgress: {
        color: "#1B59B3",
        "&::before": {
          bg: "#1B59B3",
        },
      },
    },
  }),
);

export const CourseTaskLink = styled("div")(({ completed }) =>
  css({
    cursor: completed ? "pointer" : "default",
    ":hover": {
      color: completed && "green",
    },
  }),
);
