import Axios from "axios";
import { put, takeLatest, select } from "redux-saga/effects";
import { baseURLV3, GET_POST_COMMENTS } from "utils/constants/endpoints";
import { style } from "../../style";
import { Types } from "../ducks/postComments";

const api = Axios.create({
  baseURL: baseURLV3,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});

const getCommentsActual = (state) => state.postComments.comments;

function* getPostComments({ payload }) {
  const { postId, page = 1 } = payload;

  try {
    const {
      data: { pagination, canvas_comments},
    } = yield api({
      url: GET_POST_COMMENTS(postId, page),
      method: "GET",
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? []
        : yield select(getCommentsActual);
    const newList = actualList.concat(canvas_comments);
    const hasMore = canvas_comments.length === 10;

    const action = {
      type: Types.GET_POST_COMMENTS_SUCCESS,
      canvas_comments: newList,
      pagination,
      page,
      hasMore
    };

    yield put({ ...action });

    // yield put({
    //   canvas_comments,
    //   pagination,
    //   type: Types.GET_POST_COMMENTS_SUCCESS,
    // });
  } catch (error) {
    yield put({
      error,
      type: Types.GET_POST_COMMENTS_FAILURE,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_POST_COMMENTS, getPostComments);
}
