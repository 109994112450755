import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import {
  GET_CIBRA,
  GET_CIBRA_DETAILS_PUBLISH,
} from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import { Types } from "../ducks/teamCibra";

function* getCibraMenu({ id }) {
  try {
    const listMenuCibra = yield requestAPI({
      verb: GET,
      endPoint: GET_CIBRA,
      id,
    });

    yield put({
      listMenuCibra,
      type: Types.GET_CIBRA_MENU_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_CIBRA_MENU_FAIL,
      ...error.response.data,
    });
  }
}

function* getCibraDetails({ id, idDetails, kindMenu }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: GET_CIBRA_DETAILS_PUBLISH(id),
      id: idDetails,
    });
    yield put({
      detailsPageTeamCibra: response,
      type: Types.GET_CIBRA_DETAILS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_CIBRA_DETAILS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_CIBRA_MENU, getCibraMenu);
  yield takeLatest(Types.GET_CIBRA_DETAILS, getCibraDetails);
}
