import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { GET_ALL_BIRTHDAYS } from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import moment from "moment";
import { Types } from "../ducks/birthdays";

function* getBirthdays({ nameBrand }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: nameBrand
        ? `${GET_ALL_BIRTHDAYS}?brand_name=${nameBrand}`
        : GET_ALL_BIRTHDAYS,
    });
    const displayMonthOfYear = (month) => {
      switch (month) {
        case 0:
          return "Janeiro";
        case 1:
          return "Fevereiro";
        case 2:
          return "Março";
        case 3:
          return "Abril";
        case 4:
          return "Maio";
        case 5:
          return "Junho";
        case 6:
          return "Julho";
        case 7:
          return "Agosto";
        case 8:
          return "Setembro";
        case 9:
          return "Outubro";
        case 10:
          return "Novembro";
        case 11:
          return "Dezembro";
        default:
          return null;
      }
    };

    const sortBdays = (month) => {
      const orderedBdays = month.sort(
        (current, next) =>
          moment(next.datebirthday).format("DD") -
          moment(current.datebirthday).format("DD"),
      );
      const reversedBdays = orderedBdays.reverse();
      return reversedBdays;
    };

    const birthdays = yield Object.values(response).map((month, i) => ({
      id: i,
      name: displayMonthOfYear(i),
      members: sortBdays(month),
    }));

    yield put({
      birthdays,
      type: Types.GET_BIRTHDAYS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_BIRTHDAYS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_BIRTHDAYS, getBirthdays);
}
