import styled from "styled-components";
import css from "@styled-system/css";

import Flex from "components/core/Grid/Flex";

export const MyCourses = styled(Flex)(
  css({
    overflow: "auto",
    height: "calc(100vh - 137px)",
    "@media (max-width: 320px)": {
      marginBottom: "100px",
    },
  }),
);

export const SidebarCourse = styled(Flex)(
  css({
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    borderRadius: "4px",
    width: "100%",
    padding: "8px 8px 8px 16px",
    transition: "all ease-out .25s",
    cursor: "pointer",
    "&:not(:first-child)::before": {
      content: "''",
      position: "absolute",
      top: "-10px",
      width: "90%",
      left: "5%",
      height: "1px",
      bg: "grayDefaultColor",
      pointerEvents: "none",
      transition: "all ease-out .25s",
    },
    "&:hover": {
      boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.12)",
      "&::before, + ::before": {
        bg: "transparent",
      },
      "h1::before": {
        height: "150%",
        top: "-10px",
      },
    },
  }),
  ({ completed }) =>
    css({
      h1: {
        color: completed ? "greenDark" : "blue",
        "&::before": {
          bg: completed ? "greenDark" : "blue",
        },
      },
      h3: {
        color: completed ? "greenDark" : "blue",
      },
      svg: {
        stroke: completed ? "greenDark" : "blue",
      },
    }),
);

export const SidebarCourseTitle = styled("h1")(
  css({
    fontSize: 2,
    marginBottom: 1,
    fontWeight: "600",
    fontFamily: "heading",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "6px",
      height: "100%",
      left: "-19px",
      top: "0px",
      borderRadius: "40px",
      transition: "all ease-out .25s",
    },
  }),
);

export const TitleAmountCourse = styled("div")(
  css({
    "@media (max-width: 768px)": {
      marginRight: "15px",
    },
  }),
);
