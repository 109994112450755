import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getGallery: ["payload"],
  getGallerySuccess: ["payload", "isLoading"],
  getGalleryFail: ["isLoading", "error"],

  getGalleryFolder: ["id"],
  getGalleryFolderSuccess: ["payload", "isLoading"],
  getGalleryFolderFail: ["isLoading", "error"],

  getMoreGalleryPhotos: ["id"],
  getMoreGalleryPhotosSuccess: ["payload", "isLoadingMorePhotos"],
  getMoreGalleryPhotosFail: ["isLoadingMorePhotos"],
});

const INITIAL_STATE = {
  galleryList: [],
  galleryDetails: { pagination: { next_page: 1 } },
  isLoadingGallery: true,
  isLoadingMorePhotos: false,
  error: [],
  hasMoreGallery: true,
  pageGallery: 1,
};

const getGallery = (state) => ({
  ...state,
  isLoadingGallery: true,
});
const getGallerySuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingGallery: false,
});
const getGalleryFail = (state) => ({
  ...state,
  isLoadingGallery: false,
});

const getGalleryFolder = (id) => ({ ...id, isLoadingGallery: true });
const getGalleryFolderSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingGallery: false,
});
const getGalleryFolderFail = (state) => ({
  ...state,
  isLoadingGallery: false,
});

const getMoreGalleryPhotos = (id) => ({
  ...id,
  isLoadingMorePhotos: true,
});
const getMoreGalleryPhotosSuccess = (state, { pagination, contents }) => ({
  ...state,
  galleryDetails: {
    pagination,
    gallery_contents: contents,
  },
  isLoadingMorePhotos: false,
});
const getMoreGalleryPhotosFail = (state) => ({
  ...state,
  isLoadingMorePhotos: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_GALLERY]: getGallery,
  [Types.GET_GALLERY_SUCCESS]: getGallerySuccess,
  [Types.GET_GALLERY_FAIL]: getGalleryFail,

  [Types.GET_GALLERY_FOLDER]: getGalleryFolder,
  [Types.GET_GALLERY_FOLDER_SUCCESS]: getGalleryFolderSuccess,
  [Types.GET_GALLERY_FOLDER_FAIL]: getGalleryFolderFail,

  [Types.GET_MORE_GALLERY_PHOTOS]: getMoreGalleryPhotos,
  [Types.GET_MORE_GALLERY_PHOTOS_SUCCESS]: getMoreGalleryPhotosSuccess,
  [Types.GET_MORE_GALLERY_PHOTOS_FAIL]: getMoreGalleryPhotosFail,
});
