import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getNotifications: ["payload"],
  getNotificationsSuccess: ["payload", "isLoading"],
  getNotificationsFail: ["isLoading", "error"],
});
const INITIAL_STATE = {
  notifications: [],
  isLoading: true,
  hasMore: true,
  page: 1,
};

const getNotifications = (state) => ({
  ...state,
});

const getNotificationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNotificationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS]: getNotifications,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAIL]: getNotificationsFail,
});
