/* eslint-disable no-alert */
import React, { useEffect, useRef, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";
import moment from "moment";

import { style } from "style";
import urls from "utils/constants/urls";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  smartGet,
  manageClasses,
  smartFormatterDate,
} from "helpers/sharedHelpers";
import { Creators as BirthdaysActions } from "store/ducks/birthdays";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { RadioButtonGeneric } from "components/core/Form/Form";
import { PageTitle } from "components/core/Typography/Typography";
import BirthdaysSkeleton from "containers/Birthdays/BirthdaysSkeleton";

import { ReactComponent as Birthday } from "assets/birthdays.svg";
import { ReactComponent as BackIcon } from "assets/img/icons/backIcon.svg";
import { ReactComponent as FilterIcon } from "assets/img/icons/filterIcon.svg";
import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";

import * as S from "./BirthdaysStyle";

const Birthdays = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const memberListRef = useRef();
  const currentMonth = smartGet(moment(), "month");
  const [selectedBrandName, setSelectedBrandName] = useState("");
  const [childProfiles] = useState(
    style.variables.child_profiles.concat("TODOS"),
  );
  const [showBirthdaysMonth, setShowBirthdaysMonth] = useState(false);
  const { birthdays, isLoading, selectedMonth } = useSelector(
    (state) => state.birthdays,
  );

  const dispatchGetBirthdays = useCallback(
    (nameBrand) => dispatch(BirthdaysActions.getBirthdays(nameBrand)),
    [dispatch],
  );

  useEffect(() => {
    if (!selectedMonth) {
      if (style.variables.child_profiles?.length > 0) {
        dispatchGetBirthdays(style.names.nameApp);
      } else {
        dispatchGetBirthdays();
      }
    }
  }, [selectedMonth, dispatchGetBirthdays]);

  const selectMonth = (month) => {
    setShowBirthdaysMonth(true);
    dispatch(BirthdaysActions.selectMonth(month));
  };

  const renderName = (name) => {
    const first = name[0];
    const last = name.slice(-1)[0];

    return first === last ? first : `${first} ${last}`;
  };

  const renderTitleMember = ({ sex, name }) => {
    let genres;
    switch (sex) {
      case "M":
        genres = intl.messages["general.textPage.genres.male"];
        break;
      case "F":
        genres = intl.messages["general.textPage.genres.feminine"];
        break;
      default:
        genres = intl.messages["general.textPage.genres.ungenerous"];
    }
    return `${intl.messages["general.textPage.clickOfAcessProfile"]} ${genres} ${name}`;
  };

  const birthdaysToday = () => {
    const currentDay = smartGet(moment(), "date");

    const length = birthdays[currentMonth]?.members?.filter(
      (member) => currentDay === smartGet(member.datebirthday, "date"),
    ).length;
    return (
      length > 0 && (
        <>
          <Birthday width={32} height={32} />
          <S.BirthdaysCount>
            <S.BirthdayToday>{`${intl.messages["birthdays.textPage.todayWeHave"]} `}</S.BirthdayToday>
            {length === 1
              ? `${length} ${intl.messages["birthdays.textPage.birthday"]}`
              : `${length} ${intl.messages["birthdays.textPage.birthdayPlural"]}`}
          </S.BirthdaysCount>
          <S.Calendar to={`${urls.ROUTES.BIRTHDAYS_TODAY}`}>
            {intl.messages["birthdays.textPage.know"]}
          </S.Calendar>
        </>
      )
    );
  };

  const handleSelectedBrandName = (value) => {
    if (value === "TODOS") {
      dispatchGetBirthdays();
    } else {
      dispatchGetBirthdays(value);
    }
    setSelectedBrandName(value);
  };

  const popoverEditComment = () => (
    <S.ContainerPopoverStyle>
      <S.BodyPopoveActions>
        <PageTitle title="Qual marca você deseja visualizar?" fontSize={2} />
        <S.BodyPopoveAlternative>
          <RadioButtonGeneric
            optionSelected={selectedBrandName}
            options={childProfiles}
            onChange={(e) => handleSelectedBrandName(e)}
          />
        </S.BodyPopoveAlternative>
      </S.BodyPopoveActions>
    </S.ContainerPopoverStyle>
  );

  return (
    <Portal
      contextSearch={{
        contextName: "Aniversários",
        contextAction: () => alert("Filtrar colaborador"),
      }}
    >
      <S.ContainerBirthdays>
        <S.ContainerHeader>
          <S.TittleHeader>{intl.messages["menu.birthdays"]}</S.TittleHeader>
          <S.ContainerHeaderBirthday>
            {style.variables.child_profiles?.length > 0 && (
              <S.BodyFilterBrand>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom"
                  overlay={popoverEditComment()}
                >
                  <S.ContainerFilterBrand>
                    <FilterIcon />
                  </S.ContainerFilterBrand>
                </OverlayTrigger>
              </S.BodyFilterBrand>
            )}
            {isLoading ? (
              <Skeleton duration={0.8} width={396} height={28} />
            ) : (
              birthdaysToday()
            )}
            <S.BtnOutBirthday birthdaysMonthShow={showBirthdaysMonth}>
              <BackIcon onClick={() => setShowBirthdaysMonth(false)} />
            </S.BtnOutBirthday>
          </S.ContainerHeaderBirthday>
        </S.ContainerHeader>
        {isLoading ? (
          <BirthdaysSkeleton />
        ) : (
          birthdays && (
            <S.ContentBirthdayMembersList>
              <S.BirthdayContainer
                flexDirection="column"
                spaceChildren={3}
                birthdaysMonthShow={showBirthdaysMonth}
              >
                {birthdays.map(({ id: idBirthDays, name, members }) => {
                  const avatarPeople = members.slice(0, 3);
                  return (
                    <S.MonthItem
                      key={idBirthDays}
                      variant={manageClasses({
                        selected: idBirthDays === selectedMonth?.id,
                      })}
                      onClick={() => {
                        selectMonth({ idBirthDays, name, members });
                        memberListRef.current.scrollTop = 0;
                      }}
                    >
                      <Flex flexDirection="column">
                        <S.MonthName title={name}>{name}</S.MonthName>
                        <S.CountMembers>{`${members.length} ${intl.messages["general.textPage.people"]}`}</S.CountMembers>
                      </Flex>
                      <Flex>
                        {members &&
                          avatarPeople.map(({ id: idAvatar, image }, index) => (
                            <Avatar
                              key={idAvatar}
                              border="3px solid #f4f6fb"
                              url={image?.url}
                              ml={index === 0 ? `2px` : `-12px`}
                            />
                          ))}
                        <ArrowRight />
                      </Flex>
                    </S.MonthItem>
                  );
                })}
              </S.BirthdayContainer>
              <S.MembersList
                ref={memberListRef}
                birthdaysMonthShow={showBirthdaysMonth}
              >
                {selectedMonth?.members?.map(
                  ({ id, name, image, sex, datebirthday }) => {
                    const arrayName = name.toLowerCase().split(" ");

                    const day = smartGet(datebirthday, "date");
                    const monthString = smartFormatterDate(
                      datebirthday,
                      "MMMM",
                    );

                    return (
                      <S.MemberItem
                        title={renderTitleMember({ sex, name })}
                        to={`${urls.ROUTES.PROFILE}/${id}`}
                        key={id}
                      >
                        <S.ContainerAvatar>
                          <Avatar url={image?.url} size="100%" />
                        </S.ContainerAvatar>
                        <S.MemberName title={renderName(arrayName)}>
                          {renderName(arrayName)}
                        </S.MemberName>
                        <S.MemberBirthday>
                          {day} de {monthString}
                        </S.MemberBirthday>
                      </S.MemberItem>
                    );
                  },
                )}
              </S.MembersList>
            </S.ContentBirthdayMembersList>
          )
        )}
      </S.ContainerBirthdays>
    </Portal>
  );
};

export default Birthdays;
