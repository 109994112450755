/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { Container, Rubricas } from "./PayrollStyles";
import theme from "../../../styles/theme";

// eslint-disable-next-line react/prop-types
export const Payroll = ({ data }) => {
  const { cabecalho, detalhe, totais } = data;

  return (
    <Container>
      <header>
        <img
          src="https://timcoo-geral.s3.amazonaws.com/Logos/cibra.png"
          alt="Imagem do logo da empresa"
        />
        <strong>Demonstrativo de pagamento</strong>
      </header>
      <table>
        <thead>
          <tr>
            <th width="50%">Empresa</th>
            <th width="50%">CPNJ</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{cabecalho.butxt}</td>
            <td>{cabecalho.cnpj}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Nome</th>
            <th width="25%">Matrícula</th>
            <th width="25%">UN Organizacional</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{cabecalho.cname}</td>
            <td>{cabecalho.pernr}</td>
            <td>{cabecalho.orgtx}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Cargo</th>
            <th width="25%">Salário Base</th>
            <th width="25%">Mês/Ano</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{cabecalho.stltx}</td>
            <td>{`R$ ${Number(cabecalho.salbase).toLocaleString("pt-BR")}`}</td>
            <td style={{ textTransform: "uppercase" }}>
              {moment(cabecalho.perio).format("MMMM/yyyy")}
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th>Banco</th>
            <th width="20%">Agência</th>
            <th width="20%">Conta - Dig</th>
            <th width="20%">Dt. Crédito</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{cabecalho.banka}</td>
            <td>{cabecalho.bankag}</td>
            <td>{cabecalho.bankn}</td>
            <td>{moment(cabecalho.paydt).format("DD/MM/yyyy")}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th width="20%">Admissão</th>
            <th width="20%">CPF</th>
            <th width="20%">PIS</th>
            <th width="20%">CTPS</th>
            <th width="20%">Serie</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{moment(cabecalho.admissao).format("DD/MM/yyyy")}</td>
            <td>{cabecalho.cpf}</td>
            <td>{cabecalho.pis}</td>
            <td>{cabecalho.ctps}</td>
            <td>{cabecalho.ctps_serie}</td>
          </tr>
        </tbody>
      </table>
      <Rubricas>
        <table>
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                Rubrica
              </th>
              <th
                style={{
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                Quantidade
              </th>
              <th
                style={{
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                Proventos
              </th>
              <th
                style={{
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                Descontos
              </th>
            </tr>
          </thead>

          <tbody>
            {detalhe.rubricas.length ? (
              detalhe.rubricas.map(
                ({ lgart, lgtxt, anzhl, proventos, descontos }, index) => (
                  <tr
                    key={lgart}
                    style={
                      index % 2 === 0
                        ? { backgroundColor: `${theme.colors.grayLight}` }
                        : { backgroundColor: `white` }
                    }
                  >
                    <td width="49%" style={{ textAlign: "left" }}>
                      <strong>
                        <span>{lgart}</span>
                        {lgtxt}
                      </strong>
                    </td>
                    <td width="17%">{Number(anzhl).toLocaleString("pt-BR")}</td>
                    <td width="17%">
                      {Number(proventos).toLocaleString("pt-BR")}
                    </td>
                    <td width="17%">
                      {Number(descontos).toLocaleString("pt-BR")}
                    </td>
                  </tr>
                ),
              )
            ) : (
              <tr>
                <td>
                  <strong>
                    <span />
                  </strong>
                </td>
                <td />
                <td />
                <td />
              </tr>
            )}
          </tbody>
          <thead>
            <tr style={{ border: "none" }}>
              <th
                style={{
                  border: "none",
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                <span />
              </th>
              <th
                style={{
                  border: "none",
                  backgroundColor: `${theme.colors.grayMedium}`,
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
              >
                R$
              </th>
              <th
                style={{
                  borderBottom: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                  backgroundColor: `${theme.colors.grayLight}`,
                }}
              >
                Total Proventos
              </th>
              <th
                style={{
                  border: "none",
                  backgroundColor: `${theme.colors.grayLight}`,
                }}
              >
                Total Descontos
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "none" }}>
              <td
                style={{
                  border: "none",
                  backgroundColor: `${theme.colors.grayMedium}`,
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  textAlign: "left",
                }}
              >
                Líquido &emsp; &gt;
              </td>
              <td
                style={{
                  border: "none",
                  backgroundColor: `${theme.colors.grayMedium}`,
                }}
              >
                {Number(totais.total_liquido).toLocaleString("pt-BR")}
              </td>
              <td
                style={{
                  borderTop: "none",
                  backgroundColor: `${theme.colors.grayLight}`,
                }}
              >
                {Number(totais.proventos).toLocaleString("pt-BR")}
              </td>
              <td
                style={{
                  borderTop: "none",
                  backgroundColor: `${theme.colors.grayLight}`,
                }}
              >
                {Number(totais.descontos).toLocaleString("pt-BR")}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr style={{ border: "none" }}>
              <th
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
                width="20%"
              >
                D/R
              </th>
              <th
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
                width="20%"
              >
                D/SF
              </th>
              <th
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
                width="20%"
              >
                FGTS Mês
              </th>
              <th
                style={{
                  border: "none",
                }}
                width="20%"
              >
                Salário Contribuição
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ border: "none" }}>
              <td
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
              >
                {Number(totais.dr).toLocaleString("pt-BR")}
              </td>
              <td
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
              >
                {Number(totais.dsf).toLocaleString("pt-BR")}
              </td>
              <td
                style={{
                  border: "none",
                  borderRight: `1px solid ${theme.colors.grayDefaultColor}`,
                }}
              >
                {Number(totais.fgts).toLocaleString("pt-BR")}
              </td>
              <td style={{ border: "none" }}>
                {Number(totais.contribuicao).toLocaleString("pt-BR")}
              </td>
            </tr>
          </tbody>
        </table>
      </Rubricas>
    </Container>
  );
};
