import React, { useRef, useEffect } from "react";
import propType from "prop-types";
import { useVideoContext } from "../../../context/VideoContexts";

// eslint-disable-next-line react/prop-types
const Video = ({ url, ...rest }) => {
  const videoRef = useRef(null);
  const { setVideoIsFinished } = useVideoContext();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [url]);

  const handleEnded = () => {
    setVideoIsFinished(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={videoRef} {...rest} onEnded={handleEnded}>
      <source src={url} type="video/mp4" />
    </video>
  );
};

Video.defaultProps = {
  url: propType.string,
};

export { Video };
