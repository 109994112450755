import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import { style } from "style";
import { teamFirebase, defaultFirebase } from "./teamsFirebase";

export const getTeamFirebase = () => {
  const teamSelected = teamFirebase.find(
    (team) => style.names.nameApp === team.teamName,
  );

  let defaultSettingsTeams = defaultFirebase.firebaseConfig;
  if (teamSelected) {
    defaultSettingsTeams = {
      ...defaultSettingsTeams,
      ...teamSelected.firebaseConfig,
    };
  }

  return defaultSettingsTeams;
};

if (!firebase.apps.length) {
  firebase.initializeApp(getTeamFirebase());
}

export const { analytics } = firebase;
export default firebase;
