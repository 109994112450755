/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as CalendarEventsActions } from "store/ducks/calendar";

import { ReactComponent as NoNextEvents } from "assets/newNoNextEvents.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";

import { smartFormatterDate } from "helpers/sharedHelpers";

import { MsgDoesNotExist, Overflow } from "../SidebarStyle";
import {
  DateEvent,
  Event,
  HourEvent,
  DescriptionText,
  ContainerListEvent,
  ContainerBodyList,
  SideContainerLeft,
  FooterEffectScroll,
  ContainerNoNextEvents,
} from "./EventsListStyle";

const EventList = ({ dateClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { events, nextEvents } = useSelector(({ calendar }) => calendar);
  const eventsToday = events?.filter((event) => event.date_event === dateClick);
  const eventsList = eventsToday && dateClick ? eventsToday : nextEvents;

  const [overflowHeight, setOverflowHeight] = useState();
  const [overflowHeightEvents, setOverflowHeightEvents] = useState();
  const dispatchGetNextEvents = useCallback(
    () => dispatch(CalendarEventsActions.getNextEvents()),
    [dispatch],
  );

  useEffect(() => {
    setOverflowHeightEvents(
      document.body.getBoundingClientRect().height / 2 - 183,
    );
  }, []);
  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 3 - 90);
  }, []);

  useEffect(() => {
    dispatchGetNextEvents();
  }, [dispatchGetNextEvents]);

  return (
    <Flex id="sectionEvents" flex={3} flexDirection="column" width="100%">
      <PageTitle
        title={
          intl.messages[
            `${
              dateClick
                ? "calendar.textPage.events"
                : "calendar.textPage.nextEvent"
            }`
          ]
        }
        fontSize={3}
      />
      <Overflow
        flexDirection="column"
        spaceChildren={3}
        height={`${(dateClick && overflowHeightEvents) || overflowHeight}px`}
      >
        {eventsList?.length > 0 ? (
          eventsList.map((event) => {
            const {
              id,
              title,
              kind,
              hour_event,
              description,
              hour_event_end,
              date_event,
              local,
              link,
            } = event;

            return (
              <ContainerListEvent
                key={id}
                onClick={() =>
                  document

                    .getElementById(`${id}Event`)

                    .classList.toggle("uncollapsed")
                }
                id={`${id}Event`}
                collapsed={kind === "common"}
              >
                <ContainerBodyList>
                  <SideContainerLeft />

                  <Flex flexDirection="column">
                    <Flex>
                      <DateEvent>{`${smartFormatterDate(
                        date_event,

                        "ddd",
                      )}, ${smartFormatterDate(date_event, "LL")}`}</DateEvent>
                    </Flex>

                    <Event>{title}</Event>

                    {kind === "common" && (
                      <>
                        <HourEvent>
                          {`${smartFormatterDate(
                            hour_event,
                            "LT",
                          )}h às ${smartFormatterDate(hour_event_end, "LT")}h`}
                        </HourEvent>
                      </>
                    )}
                  </Flex>

                  {kind === "common" && (
                    <ArrowLeftIcon className="collapse-trigger" />
                  )}
                </ContainerBodyList>

                {kind === "common" && local && (
                  <DescriptionText className="collapse-element">
                    <p>{local}</p>
                  </DescriptionText>
                )}

                {kind === "common" && link && (
                  <DescriptionText className="collapse-element">
                    <a href={link} target="_blank">{`URL: ${link}`}</a>
                  </DescriptionText>
                )}

                {kind === "common" && (
                  <DescriptionText className="collapse-element">
                    <p>{description}</p>
                  </DescriptionText>
                )}
              </ContainerListEvent>
            );
          })
        ) : (
          <Flex p={2} alignItems="center">
            <ContainerNoNextEvents>
              <NoNextEvents />
              <MsgDoesNotExist>
                {intl.messages["calendar.textPage.notNextEvents"]}.
              </MsgDoesNotExist>
            </ContainerNoNextEvents>
          </Flex>
        )}
      </Overflow>
      <FooterEffectScroll />
    </Flex>
  );
};

EventList.propTypes = {
  // eslint-disable-next-line react/require-default-props
  dateClick: PropTypes.string,
};

export default EventList;
