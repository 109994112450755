import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import css from "@styled-system/css";

import * as S from "./MuralStyle";

export const Card = styled("span")(
  css({
    bg: "paleGrey",
    borderRadius: "18px",
    height: "156px",
  }),
);

const SkeletonMural = () => (
  <>
    <S.Post>
      <Skeleton duration={0.8} height="450px" />
    </S.Post>
    <S.Post>
      <Card />
    </S.Post>
    <S.Post>
      <Skeleton duration={0.8} height="450px" />
    </S.Post>
  </>
);

export default SkeletonMural;
