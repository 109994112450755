import React, { useState } from "react";
import { Input } from "components/core/Form/Form";
import Button from "components/core/Button/Button";
import { useDispatch } from "react-redux";
import { Creators as AuthActions } from "store/ducks/auth";
import { style } from "style";

import * as S from "./TeamsLoginStyle";

const TeamsLogin = () => {
  const dispatch = useDispatch();

  const [token, setToken] = useState("");

  const handleToken = (event) => {
    event.preventDefault();

    dispatch(AuthActions.validateTeamsToken({ token }));
  };

  return (
    <S.AuthWrapper>
      <S.AuthContainer>
        {style.icons.loginIcon && (
          <S.LogoCompany src={style.icons.loginIcon} alt="" />
        )}
        <S.AuthParagraph>
          Quase lá! Insira abaixo o token de autenticação <br /> gerado em{" "}
          <S.AuthLink
            target="_blank"
            href="https://cibra.appnoz.com.br/configuracoes"
          >
            cibra.appnoz.com.br
          </S.AuthLink>
          .
        </S.AuthParagraph>
        <S.AuthForm onSubmit={(event) => handleToken(event)}>
          <Input
            type="text"
            name="token"
            placeholder="Token de autenticação Microsoft Teams"
            onChange={(e) => setToken(e.target.value)}
            value={token}
          />
          <Button
            style={{ alignSelf: "center" }}
            mt={4}
            type="submit"
            name="Acessar"
          />
        </S.AuthForm>
      </S.AuthContainer>
    </S.AuthWrapper>
  );
};

export default TeamsLogin;
