import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";
import { Link } from "react-router-dom";
import Popover from "react-bootstrap/Popover";
import { style } from "style";

import Flex from "components/core/Grid/Flex";

export const SkeletonContainer = styled(Flex)`
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 767px) and (max-width: 1023px) {
    width: 40%;
  }

  @media (min-width: 1024px) {
    width: 32%;
  }
`;

export const TeamContainer = styled(Flex)(
  css({
    height: "100%",
    position: "relative",
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    borderRight: "1px solid",
    borderColor: "grayDefaultColor",

    "@media (max-width: 539px)": {
      paddingBottom: "150px",
    },

    "@media (max-width: 767px)": {
      width: "100%",
      border: "none",
    },

    "@media (min-width: 767px) and (max-width: 1023px)": {
      width: "40%",
    },

    "@media (min-width: 1024px)": {
      width: "32%",
    },
  }),
  ({ teamMembersShow }) =>
    !teamMembersShow
      ? css({
          "@media (max-width: 766px)": {
            animation: "showBirthdaysMonth 1s ease-in",
            "@keyframes showBirthdaysMonth": {
              from: {
                width: "0%",
                visibility: "hidden",
              },
              to: {
                width: "100%",
                visibility: "visible",
              },
            },
          },
        })
      : css({
          "@media (max-width: 766px)": {
            animation: "hideBirthdaysMonth 1s ease-in",
            width: "0%",
            visibility: "hidden",
            "@keyframes hideBirthdaysMonth": {
              from: {
                width: "100%",
                visibility: "hidden",
              },
              to: {
                width: "0%",
                visibility: "hidden",
              },
            },
          },
        }),
);

export const ContainerTeams = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "auto",
    padding: "0 16px",
    position: "relative",
    flexDirection: "column",
    overflowX: "hidden",
    "@media (max-width: 320px)": {
      height: "calc(100vh - 250px)",
    },
    "@media (min-width: 767px) and (max-width: 1023px)": {
      height: "calc(100vh - 290px)",
    },
  }),
);

export const TeamItemStyle = styled("div")(
  css({
    bg: "paleGrey",
    width: "100%",
    display: "flex",
    padding: "12px",
    margin: "8px 0px",
    cursor: "pointer",
    minHeight: "68px",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "space-between",
    transition: "1s",
    ":hover, :focus": {
      opacity: "0.8",
    },
    svg: {
      marginTop: "4px",
      visibility: "visible",
    },
    "svg path": {
      stroke: "secondaryColor",
    },
    "@media (min-width: 768px)": {
      svg: {
        visibility: "hidden",
      },
    },
  }),
  variant({
    variants: {
      selected: {
        bg: "secondaryColor",
        "h2, p": {
          color: "white",
        },
        "svg path": {
          stroke: "white",
        },
      },
    },
  }),
);

export const TeamName = styled("h2")(
  css({
    margin: 0,
    fontSize: 2,
    lineHeight: "24px",
    color: "backText",
    maxWidth: "190px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "heading",
  }),
);

export const CountMembers = styled("p")(
  css({
    margin: 0,
    fontSize: 1,
    lineHeight: "20px",
    color: "secondaryColor",
    fontFamily: "text",
  }),
);

export const MembersList = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "start",
    "@media (max-width: 539px)": {
      paddingBottom: "150px",
    },
    "@media (min-width: 767px) and (max-width: 1023px)": {
      width: "60%",
    },
    "@media (min-width: 1024px)": {
      width: "68%",
    },
  }),
  ({ teamMembersShow }) =>
    !teamMembersShow &&
    css({
      "@media (max-width: 767px)": {
        width: "0%",
        height: "0%",
      },
    }),
);

export const ContainerMembers = styled("div")(
  css({
    width: "100%",
    height: "100%",
    padding: "24px",
    display: "flex",
    overflow: "auto",
    flex: "1 1 auto",
    flexWrap: "wrap",
    zIndex: "1",
    alignContent: "flex-start",
    bg: "white",
    "@media (min-width: 767px)": {
      svg: {
        height: "430px",
        visibility: "hidden",
      },
    },
  }),
  ({ teamMembersShow }) =>
    teamMembersShow
      ? css({
          "@media (max-width: 767px)": {
            animation: "showBirthdays 1s ease-in",
            visibility: "visible",
            "@keyframes showBirthdays": {
              from: {
                visibility: "visible",
              },
              to: {
                visibility: "hidden",
              },
            },
          },
        })
      : css({
          "@media (max-width: 767px)": {
            animation: "hideBirthdays 1s ease-in",
            visibility: "hidden",
            "@keyframes hideBirthdays": {
              from: {
                visibility: "hidden",
              },
              to: {
                visibility: "visible",
              },
            },
          },
        }),
);

export const ContainerSearch = styled("div")(
  css({
    width: "100%",
    height: "10%",
    display: "flex",
    alignItems: "center",
    padding: "10px 16px",
  }),
  ({ teamMembersShow }) =>
    teamMembersShow
      ? css({
          visibility: "visible",
        })
      : css({
          width: 0,
          visibility: "hidden",
          "@media (min-width: 767px)": {
            width: "100%",
            visibility: "visible",
          },
        }),
);

export const TeamMember = styled(Link)(
  css({
    display: "flex",
    flexDirection: "column",
    marginRight: 3,
    marginBottom: 4,
    ":hover": {
      textDecoration: "none",
    },
  }),
);

export const MemberName = styled("h3")(
  css({
    margin: 0,
    fontSize: 2,
    paddingLeft: 3,
    paddingRight: 1,
    lineHeight: "25px",
    color: "backText",
    fontFamily: "text",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "@media (min-width: 768px)": {
      maxWidth: "116px",
      maxHeight: "118px",
    },
    maxWidth: "110px",
    maxHeight: "110px",
  }),
);

export const ContentTeamMembersList = styled("div")(
  css({
    width: "100%",
    display: "flex",
    height: `calc(100vh - ${
      style.variables.child_profiles?.length > 0 ? "215px" : "200px"
    })`,
  }),
);

export const BtnOutTeam = styled("div")(
  css({
    svg: {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
    ":hover, :focus": {
      "svg path": {
        stroke: "secondaryColor",
        opacity: "0.8",
      },
    },
    "@media (min-width: 768px)": {
      visibility: "hidden",
    },
  }),
  ({ teamMembersShow }) =>
    teamMembersShow
      ? css({
          "@media (max-width: 767px)": {
            animation: "showBtnBirthdays 2s ease-in",
            visibility: "visible",
            "@keyframes showBtnBirthdays": {
              from: {
                visibility: "visible",
              },
              to: {
                visibility: "hidden",
              },
            },
          },
        })
      : css({
          "@media (max-width: 767px)": {
            animation: "hideBtnBirthdays 1s ease-in",
            visibility: "hidden",
            "@keyframes hideBtnBirthdays": {
              from: {
                visibility: "hidden",
              },
              to: {
                visibility: "visible",
              },
            },
          },
        }),
);

export const ContainerAvatarTeam = styled("div")(
  css({
    width: "110px",
    height: "110px",
    "@media (min-width: 768px)": {
      width: "116px",
      height: "118px",
    },
  }),
);

export const ContainerFilterBrand = styled("div")(
  css({
    width: "40px",
    height: "40px",
    border: "none",
    display: "flex",
    transition: "1s",
    cursor: "pointer",
    marginLeft: "5px",
    alignItems: "center",
    borderRadius: "100px",
    justifyContent: "center",
    backgroundColor: "paleGrey",
    "&:hover": {
      opacity: 0.7,
    },
  }),
);

export const ContainerPopoverStyle = styled(Popover)(
  css({
    borderRadius: 4,
    width: 300,
    border: "none",
    marginTop: 15,
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const BodyPopoveActions = styled("div")(
  css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const BodyPopoveAlternative = styled("div")(
  css({
    width: "100%",
    display: "flex",
    flexDirection: "column",
  }),
);

export const ContainerFilterTrigger = styled("div")(
  css({
    position: "absolute",
    right: 10,
    "@media (max-width: 767px)": {
      right: 50,
    },
  }),
);
