/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ActionTeamCibra } from "store/ducks/teamCibra";

import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";

import DetailsComponent from "components/presentation/GroupPardini/DetailsComponent";

import SkeletonSubMenuCibra from "./SkeletonSubMenuCibra/SkeletonSubMenuCibra";

const SubMenuCibra = ({idMenu}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isLoading, detailsPageTeamCibra } = useSelector(
    (state) => state.teamCibra,
  );
  const { title, contents } = detailsPageTeamCibra;

  const dispatchGetCibraDetails = useCallback(() => {
    dispatch(ActionTeamCibra.getCibraDetails(idMenu, id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatchGetCibraDetails();
  }, [dispatchGetCibraDetails]);

  return (
    <Portal>
      <PageTitle title={title} />
      {isLoading ? (
        <SkeletonSubMenuCibra />
      ) : (
        <DetailsComponent contentsList={contents} />
      )}
    </Portal>
  );
};

export default SubMenuCibra;
