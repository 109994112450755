import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import Carousel from "components/core/Carousel";
import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";

import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";

import * as S from "./DetailsComponentStyle";

const DetailsComponent = ({ contentsList }) => {
  const history = useHistory();
  const goBack = () => history.goBack();

  const renderContentDetails = ({
    id: idContent,
    title: titleContent,
    kind,
    text,
    images,
    link,
  }) => {
    switch (kind) {
      case "image":
        return <S.ImgContainer src={images[0]?.image.url} />;
      case "text":
        return (
          <S.TextContent>
            <S.Text dangerouslySetInnerHTML={{ __html: text }} />
          </S.TextContent>
        );
      case "carousel":
        return (
          <S.CardContentCarrousel>
            <Carousel
              key={idContent}
              showButtonCarousel
              itensRender={images.map(({ id: idImage, image }) => (
                <S.Image key={idImage} src={image.url} />
              ))}
            />
          </S.CardContentCarrousel>
        );
      case "link":
        return (
          <S.KnowMoreButton
            name={titleContent}
            variant={["gradient"]}
            action={() => window.open(`${link}`)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <S.GeneralContainer>
      <GoBackButton onClick={goBack}>
        <GoBackIcon />
      </GoBackButton>
      <S.ContentDetails>
        {contentsList?.map((content) => renderContentDetails(content))}
      </S.ContentDetails>
    </S.GeneralContainer>
  );
};

DetailsComponent.defaultProps = {
  contentsList: [],
};

DetailsComponent.propTypes = {
  contentsList: PropTypes.arrayOf(PropTypes.object),
};

export default DetailsComponent;
