import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { LINKS_APPS } from "utils/constants/endpoints";
import { Types } from "../ducks/apps";

function* getAllFiles() {
  try {
    const apps_response = yield requestAPI({
      verb: GET,
      endPoint: LINKS_APPS,
      data: null,
    });
    yield put({
      apps: apps_response,
      type: Types.GET_APPS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_APPS_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_APPS, getAllFiles);
}
