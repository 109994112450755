import styled from "styled-components";
import css from "@styled-system/css";

export const ContainerSelectedOption = styled("div")(
  css({
    fontSize: "14px",
    transition: "1s",
    cursor: "pointer",
    lineHeight: "24px",
    fontFamily: "text",
    fontStyle: "normal",
    borderRadius: "4px",
    padding: "6px 20px",
    textAlign: "center",
    fontWeight: "normal",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    color: "defaultBackground",
    backgroundColor: "primaryColor",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)",
    svg: {
      marginRight: "10px",
      path: {
        stroke: "defaultBackground",
      },
    },
    ":hover": {
      opacity: 0.7,
    },
  }),
);
