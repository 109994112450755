import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";

export const WordSearched = styled("h4")(
  css({
    mb: 2,
    fontSize: 2,
    color: "gray",
    display: "block",
    fontFamily: `${theme.fonts.textItalic}`,
  }),
);

export const CircleSearched = styled("div")(
  css({
    mr: 2,
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor: `${theme.colors.secondaryColor}`,
  }),
);
export const Post = styled("article")(
  css({
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    span: {
      width: "590px",
      borderRadius: "18px",
    },
    marginBottom: "37px",
    "@media (max-width: 419px)": {
      span: {
        width: "100%",
      },
    },
    "@media (min-width: 420px) and (max-width: 767px)": {
      span: {
        width: 400,
      },
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      span: {
        width: 420,
      },
    },
  }),
);

export const HeaderInSideBar = styled("div")(
  css({
    "@media (max-width: 767px)": {
      width: "100%",
      height: "50px",
    },
  }),
);

export const ContainerNoResults = styled("div")(
  css({
    width: "100%",
    height: "100%",
    display: "flex",
    marginTop: "80px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    svg: {
      size: "240px",
    },
  }),
);

export const ContainerTittle = styled("div")(
  css({
    fontSize: "30px",
    lineHeight: "24px",
    color: "textConfig",
    marginTop: "50px",
  }),
);
