import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { RadioButton } from "components/core/Form/Form";
import Button from "components/core/Button";

import { manageClasses } from "helpers/sharedHelpers";

const Survey = ({
  contentId,
  options,
  handleSurvey,
  sendSurvey,
  allVotesAmount,
  endDate,
}) => {
  const [survey, setSurveys] = useState({
    canvas_content_id: contentId,
    survey_id: null,
  });

  const userId = localStorage.getItem("idUser");
  const date = useState(new Date());

  const hasUserVoted = useCallback(
    (usersWhoVoted = []) =>
      !!usersWhoVoted?.find((user) => Number(user.id) === Number(userId)),
    [userId],
  );
  const hasBeenVoted = useCallback(
    () =>
      options.find((op) => op.voted || hasUserVoted(op.users_who_voted)) !==
        undefined && true,
    [options, hasUserVoted],
  );

  const handleState = (value) => {
    value !== survey.survey_id && setSurveys({ ...survey, survey_id: value });
  };

  const surveyExpired = useMemo(() => Date.parse(endDate) < Date.parse(date[0]), [
    endDate,
    date,
  ]);

  useEffect(() => {
    survey.survey_id !== null && handleSurvey(survey);
    // eslint-disable-next-line
  }, [survey]);

  return (
    <>
      {options.map(
        ({
          id,
          title,
          voted,
          votes_amount,
          users_who_voted: usersWhoVoted,
        }) => (
          <RadioButton
            disabled={hasBeenVoted() || surveyExpired}
            key={id}
            id={id}
            label={title}
            checked={
              id === survey.survey_id || voted || hasUserVoted(usersWhoVoted)
            }
            group={`survey${contentId}--option${id}`}
            onChange={({ target: { value } }) => handleState(Number(value))}
            allVotesAmount={allVotesAmount}
            votesAmount={votes_amount || usersWhoVoted?.length || 0}
            endDate={endDate}
          />
        ),
      )}

      {sendSurvey && !hasBeenVoted() && survey.survey_id !== null && !surveyExpired && (
        <Button
          variant={manageClasses("big")}
          action={sendSurvey}
          name="Enviar"
        />
      )}
    </>
  );
};

Survey.propTypes = {
  contentId: PropTypes.number.isRequired,
  options: PropTypes.instanceOf(Array).isRequired,
  handleSurvey: PropTypes.func.isRequired,
};

export default Survey;
