import React, { createContext, useContext, useState } from "react";

const VideoContext = createContext({});

export const VideoProvider = ({ children }) => {
  const [videoIsFinished, setVideoIsFinished] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  return (
    <VideoContext.Provider
      value={{
        videoIsFinished,
        setVideoIsFinished,
        isVideo,
        setIsVideo,
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};

export const useVideoContext = () => {
  const context = useContext(VideoContext);

  return context;
};
