import styled from "styled-components";
import css from "@styled-system/css";

export const BoxLoadingLoginSaml = styled("div")(
  css({
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }),
);
