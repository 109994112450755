import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import {
  GET_PARDINI,
  GET_PARDINI_DETAILS_PUBLISH,
} from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import { Types } from "../ducks/groupPardini";

function* getPardiniMenu({ id }) {
  try {
    const listMenuGroupPardini = yield requestAPI({
      verb: GET,
      endPoint: GET_PARDINI,
      id,
    });

    yield put({
      listMenuGroupPardini,
      type: Types.GET_PARDINI_MENU_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PARDINI_MENU_FAIL,
      ...error.response.data,
    });
  }
}

function* getPardiniDetails({ id, idDetails, kindMenu }) {
  try {
    const response = yield requestAPI({
      verb: GET,
      endPoint: GET_PARDINI_DETAILS_PUBLISH(id),
      id: idDetails,
    });
    yield put(
      kindMenu
        ? {
            subMenuList: response,
            type: Types.GET_PARDINI_DETAILS_SUCCESS,
          }
        : {
            detailsPageGroupPardini: response,
            type: Types.GET_PARDINI_DETAILS_SUCCESS,
          },
    );
  } catch (error) {
    yield put({
      type: Types.GET_PARDINI_DETAILS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_PARDINI_MENU, getPardiniMenu);
  yield takeLatest(Types.GET_PARDINI_DETAILS, getPardiniDetails);
}
