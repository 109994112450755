import { takeLatest, put, select } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import { GALLERY, GALLERY_FOLDER } from "utils/constants/endpoints";
import { Types } from "../ducks/gallery";

const getFoldersActual = (state) => state.gallery.galleryList;
const pageActual = (state) => state.gallery.pageGallery;

const getGalleryContentsActual = (state) =>
  state.gallery.galleryDetails.gallery_contents;
const galleryContentsNextPage = (state) =>
  state.gallery.galleryDetails.pagination.next_page;

function* getGallery() {
  const page = yield select(pageActual);
  try {
    const folders = yield requestAPI({
      verb: GET,
      endPoint: `${GALLERY}?per_page=25&page=${page}`,
      data: null,
    });
    if (folders.galleries) {
      const actualList = page === 1 ? [] : yield select(getFoldersActual);
      const newList = actualList.concat(folders.galleries);
      const hasMoreGallery = !!folders.galleries.length;
      const nextpage = page + 1;
      yield put({
        galleryList: newList,
        hasMoreGallery,
        pageGallery: nextpage,
        type: Types.GET_GALLERY_SUCCESS,
      });
    } else {
      yield put({
        type: Types.GET_GALLERY_FAIL,
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_GALLERY_FAIL,
      ...error.response.data,
    });
  }
}

function* getGalleryFolder({ id }) {
  try {
    const galleryDetails = yield requestAPI({
      verb: GET,
      endPoint: GALLERY_FOLDER,
      id,
    });
    yield put({
      galleryDetails,
      type: Types.GET_GALLERY_FOLDER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_GALLERY_FOLDER_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* getMoreGalleryPhotos({ id }) {
  const nextPage = yield select(galleryContentsNextPage);
  try {
    const galleryDetails = yield requestAPI({
      verb: GET,
      endPoint: `${GALLERY_FOLDER(id)}/?page=${nextPage}`,
    });
    if (galleryDetails.gallery_contents) {
      const actualList = yield select(getGalleryContentsActual);
      const newList = actualList.concat(galleryDetails.gallery_contents);
      yield put({
        pagination: galleryDetails.pagination,
        contents: newList,
        type: Types.GET_MORE_GALLERY_PHOTOS_SUCCESS,
      });
    }
  } catch (error) {
    yield put({
      type: Types.GET_MORE_GALLERY_PHOTOS_FAIL,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_GALLERY, getGallery);
  yield takeLatest(Types.GET_GALLERY_FOLDER, getGalleryFolder);
  yield takeLatest(Types.GET_MORE_GALLERY_PHOTOS, getMoreGalleryPhotos);
}
