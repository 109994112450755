import React from "react";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import css from "@styled-system/css";

import Flex from "components/core/Grid/Flex";

export const Calendar = styled("div")(
  css({
    bg: "paleGrey",
    width: "100%",
    height: "126px",
    borderRadius: "16px",
  }),
  ({ radius }) => radius && css({ borderRadius: radius || "16px" }),
);

const CalendarSkeleton = () => (
  <Flex p={3} flexDirection="column" mt={4} spaceChildren={4}>
    <Skeleton duration={0.8} width={145} height={33} />

    <Flex flexDirection="column">
      <Flex justifyContent="space-around">
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
        <Skeleton duration={0.8} width={40} height={24} />
      </Flex>
      <Skeleton duration={0.8} height={81} />
      <Skeleton duration={0.8} height={81} />
      <Skeleton duration={0.8} height={81} />
      <Skeleton duration={0.8} height={81} />
      <Skeleton duration={0.8} height={81} />
      <Skeleton duration={0.8} height={81} />
    </Flex>
  </Flex>
);

export default CalendarSkeleton;
