import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getBirthdays: ["nameBrand"],
  getBirthdaysSuccess: ["payload", "isLoading"],
  getBirthdaysFail: ["isLoading", "error"],
  selectMonth: ["payload"],
  birthdaysNow: ["payload"],
});

const INITIAL_STATE = {
  isLoading: true,
  isLoadingBirthdays: true,
  birthdays: [],
  birthdaysNow: [],
  error: [],
  selectedMonth: null,
};

const getBirthdays = (state) => ({ ...state, isLoading: true });

const getBirthdaysSuccess = (state, payload) => ({
  ...state,
  ...payload,
  selectedMonth: payload.birthdays[new Date().getMonth()],
  isLoading: false,
});

const getBirthdaysFail = (state, payload) => ({
  ...state,
  error: [...payload],
  isLoading: false,
});

const selectMonth = (state, { payload }) => ({
  ...state,
  selectedMonth: payload,
});

const birthdaysNow = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_BIRTHDAYS]: getBirthdays,
  [Types.GET_BIRTHDAYS_SUCCESS]: getBirthdaysSuccess,
  [Types.GET_BIRTHDAYS_FAIL]: getBirthdaysFail,
  [Types.SELECT_MONTH]: selectMonth,
  [Types.BIRTHDAYS_NOW]: birthdaysNow,
});
