import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as ActionGroupPardini } from "store/ducks/groupPardini";

import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";
import SkeletonApps from "components/presentation/Apps/SkeletonApps";
import MenuComponent from "components/presentation/GroupPardini/MenuComponent";

const GroupPardini = () => {
  const dispatch = useDispatch();
  const { isLoading, listMenuGroupPardini } = useSelector(
    (state) => state.groupPardini,
  );

  const dispatchGetPardiniMenu = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniMenu(1));
  }, [dispatch]);

  useEffect(() => {
    dispatchGetPardiniMenu();
  }, [dispatchGetPardiniMenu]);

  return (
    <Portal>
      <PageTitle title={listMenuGroupPardini.title} />
      {isLoading ? (
        <SkeletonApps />
      ) : (
        <MenuComponent listMenu={listMenuGroupPardini.root_pages} />
      )}
    </Portal>
  );
};

export default GroupPardini;
