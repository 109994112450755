/* eslint-disable no-undef-init */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Input } from "components/core/Form/Form";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button/Button";
import Portal from "templates/Portal";
import { PageTitle } from "components/core/Typography/Typography";
import { history } from "helpers/sharedHelpers";
import { Creators as AuthActions } from "store/ducks/guestAuth";
import { ReactComponent as SvgLogo } from "assets/img/Logo.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import urls from "utils/constants/urls";
import { style } from "style";
import * as S from "../../Login/LoginStyle";

const LoginHolerite = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isLoading } = useSelector((state) => state.guestAuth);

  const handleLogin = async (event) => {
    event.preventDefault();

    dispatch(AuthActions.guestAuthRequest({ email, password }));
  };

  useEffect(() => {
    localStorage.setItem("nameApp", style.api.nameProfile);
  }, []);

  return (
    <Portal>
      <PageTitle title={intl.messages["menu.holerite"]} />
      <S.LoginContainer>
        <S.ContainerArrowLeft
          showArrowLeft={history.location.pathname === urls.ROUTES.SIGNUP}
        >
          <ArrowLeftIcon onClick={() => history.push(urls.ROUTES.LOGIN)} />
        </S.ContainerArrowLeft>
        <S.LogoCompany>
          {style.icons.loginIcon ? (
            <img src={style.icons.loginIcon} alt="" />
          ) : (
            <SvgLogo />
          )}
        </S.LogoCompany>
        <S.LoginForm>
          <S.Form onSubmit={(event) => handleLogin(event)}>
            <Flex mb={4}>
              <Input
                type="text"
                name="email"
                placeholder={`${intl.messages["login.textPage.typeYourEmail"]}`}
                label={intl.messages["general.user"]}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Flex>
            <Input
              type="password"
              name="password"
              value={password}
              placeholder={`${intl.messages["login.textPage.typeYourPassword"]}`}
              label={intl.messages["general.password"]}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              style={{ alignSelf: "center" }}
              mt={4}
              type="submit"
              variant={["gradient"]}
              name={intl.messages["general.buttons.enter"]}
              isLoading={isLoading}
            />
          </S.Form>
        </S.LoginForm>
      </S.LoginContainer>
    </Portal>
  );
};

export default LoginHolerite;
