import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const ContainerStore = styled(Flex)(
  css({
    // minHeight: 84,
    borderRadius: 8,
    padding: "8px 10px",
    backgroundColor: "paleGrey",

    img: {
      width: 75,
      height: 62,
      marginRight: 2,
      objectFit: "contain",
    },
    h3: {
      margin: 0,
      fontSize: 14,
      maxHeight: 24,
      maxWidth: 198,
      // fontWeight: 600,
      "-webkit-line-clamp": "1",
    },
  }),
);

export const RequestDate = styled("h4")(
  css({
    fontSize: 12,
    color: "gray",
    fontFamily: "text",
    lineHeight: "16px",
  }),
);

export const MoreInformations = styled("div")(
  css({
    fontSize: 12,
    color: theme.colors.primaryColor,
    lineHeight: "24px",
    cursor: "pointer",
    backgroundColor: "red",

    "@media (max-width: 539px)": {
      paddingLeft: "10px",
      paddingRight: "3px",
    },
    "&:nth-child(even)": {
      bg: "paleGrey",
    },
    "> svg": {
      width: "22px",
      height: "22px",
      marginRight: 2,
    },
    ".collapse-trigger": {
      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: "auto",
      transition: "all 0.4s",
      transform: "rotate(-90deg)",
      path: {
        stroke: theme.colors.primaryColor,
      },
    },
    "&.uncollapsed": {
      ".collapse-trigger": {
        transform: "rotate(90deg)",
      },
      ".collapse-element": {
        maxHeight: "80px",
        paddingTop: 2,
      },
    },
  }),
  ({ collapse }) => collapse && css({ cursor: "pointer" }),
)

export const DefaultMessage = styled("p")(
  css({
    fontSize: 12,
    color: "gray",
    fontFamily: "text",
    paddingTop: 0,
    marginBottom: 0,
    maxHeight: 0,
    overflow: "hidden",
    transition: "all 0.4s ease-out",
  }),
);
