import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import moment from "moment";

import { style } from "style";
import { history } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import { Creators as UserActions } from "store/ducks/user";

import Button from "components/core/Button/Button";
import { RadioButtonGeneric } from "components/core/Form/Form";

import { ReactComponent as IconTag } from "assets/iconTag.svg";
import ilustraTopper from "assets/ilustraTopper.png";
import ilustraThank from "assets/ilustraThank.png";

import * as S from "./ResearchSuvinilStyle";

const ResearchSuvinil = () => {

    const dispatch = useDispatch();
  const [selectedAutoDeclared, setSelectedAutoDeclared] = useState("");
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [form, setForm] = useState({});

  const optionsAutoDeclared = ["Consumidor","Pintor","Quero me formar pintor","Balconista","Arquiteto / Decorador"];

  const { user, updateDateUserSuccess, isLoading } = useSelector(
    (state) => state.user,
  );

  useEffect(() => {
    (style.names.nameApp === "Pintou Parceria Suvinil" ||  
    style.names.nameApp === "sample") &&
      !isLoading &&
      user?.auto_declared &&
      history.push(`${urls.ROUTES.MURAL}`);
  }, [user, isLoading]);

  const sendAutoDeclared = () => {
    const newUser = form;
    const autoDeclaredConvert = {
        "Pintor": "painter",
        "Consumidor":"consumer",
        "Quero me formar pintor":"become_painter",
        "Balconista":"clerk",
        "Arquiteto / Decorador": "architec",
    }
    newUser.auto_declared = autoDeclaredConvert[selectedAutoDeclared];
    newUser.isToSuvinilResearch = true;
    dispatch(UserActions.updateDataUser(newUser));
  };

  const updateSexGender = (genderUser) => {
    const Genders = {
      "Mulher cis": "cis_woman",
      "Mulher trans": "trans_woman",
      "Homem cis": "cis_man",
      "Homem trans": "trans_man",
      "Não binário": "not_binary",
      "Prefere não se identificar": "prefers_not_to_identify",
      default: "other",
    };

    return Genders[genderUser] || Genders.default;
  };

  useEffect(() => {
    if (Object.keys(user).length) {
      setForm({
        image: user.image.url,
        name: user.name,
        email: user.email,
        sex: updateSexGender(user.sex),
        custom_gender: updateSexGender(user.sex) === "other" ? user.sex : "",
        show_gender: user.show_gender,
        datebirthday: moment(user.datebirthday).utc().format("DD-MM-YYYY"),
        bio: user.bio || "",
        interests: user.interests || "",
        skills: user.skills || "",
        contacts: [...user.contacts],
        show_datebirthday: user.show_datebirthday,
        show_email: user.show_email,
        show_phone: user.show_phone,
        zip_code: user.address !== null ? user.address.zip_code : "",
        street: user.address !== null ? user.address.street : "",
        neighborhood: user.address !== null ? user.address.neighborhood : "",
        state_id: user.state !== null ? user.state.id : "",
        state: user.state !== null ? user.state.uf : "",
        city_id: user.city !== null ? user.city.id : "",
        city: user.city !== null ? user.city.name : "",
        number:  user.address !== null ? user.address.number : "",
        complement: user.address !== null && user.address.complement !== null && user.address.complement !== "null" ? user.address.complement : "",

      });
    }
  }, [user]);

  useEffect(()=>{
    if(updateDateUserSuccess) setAlreadyAnswered(true);
  },[updateDateUserSuccess]);

  useEffect(() => {
    dispatch(UserActions.getUserToEditRequest());
  }, [dispatch]);

  useEffect(() => {
    alreadyAnswered &&
      setTimeout(() => {
        history.push(`${urls.ROUTES.MURAL}`);
      }, 5000);
  }, [alreadyAnswered]);

  return (
    <S.Container>
      {alreadyAnswered ? (
        <S.Body height={"35rem"}>
          <S.TitleContainer height={"22rem"} width={"18rem"}>
            <S.Tag>
              <IconTag />
              <S.TagText>Pesquisa rápida</S.TagText>
            </S.Tag>
            <S.Title width={"100%"} >Obrigado pela sua resposta!</S.Title>
            <S.Subtitle width={"100%"}>
              Você será direcionado ao app automaticamente.
            </S.Subtitle>
            <img alt="Logo" src={style.icons.menuIcon} />
          </S.TitleContainer>
          <img alt="thank" src={ilustraThank} className="img-thank" />
        </S.Body>
      ) : (
        <>
          <S.Header>
            <img alt="Logo" src={style.icons.menuIcon} />
            <S.TitleContainer hasBorder={true}>
              <S.Tag>
                <IconTag />
                <S.TagText>Pesquisa rápida</S.TagText>
              </S.Tag>
              <S.Title>
                Olá, gostaríamos de saber um pouco mais sobre você!
              </S.Title>
              <S.Subtitle>
                Com qual dos perfis abaixo você melhor se identifica?
              </S.Subtitle>
            </S.TitleContainer>
          </S.Header>
          <S.Body>
            <img alt="talk" src={ilustraTopper} />
            <S.Form>
              <RadioButtonGeneric
                optionSelected={selectedAutoDeclared}
                options={optionsAutoDeclared}
                onChange={(e) => setSelectedAutoDeclared(e)}
                styleToSuvinilResearch={true}
              />

              <Button
                style={{"margin-top": "1.8rem", "margin-left": "1rem"}}
                name="Enviar"
                variant={optionsAutoDeclared.includes(selectedAutoDeclared) ? [] : ["disabled"]}
                onClick={sendAutoDeclared}
              />
            </S.Form>
          </S.Body>
        </>
      )}
      <S.Footer />
    </S.Container>
  );
};

export default ResearchSuvinil;
