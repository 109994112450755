import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";

const ContentModule = () => {
  return (
    <>
      <Skeleton duration={0.8} width={490} height={30} />
      <Skeleton duration={0.8} width={490} height={345} />
      <Flex mt={4} justifyContent="flex-end" spaceChildren={3}>
        <Skeleton duration={0.8} width={110} height={37} />
        <Skeleton duration={0.8} width={110} height={37} />
      </Flex>
    </>
  );
};

export default ContentModule;
