import css from "@styled-system/css";
import styled from "styled-components";
import theme from "../../styles/theme";

export const Form = styled("form")(
  css({
    display: "flex",
    flexDirection: "row",

    gap: "1rem",

    select: {
      borderRadius: "4px",
    },
  }),
);

export const Error = styled("div")(
  css({
    height: "100%",
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    h3: {
      fontSize: "1.5rem",
      color: ` ${theme.colors.grayDark}`,
    },
  }),
);

export const Footer = styled("footer")(
  css({
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    padding: "0 40px 40px",
  }),
);

export const ButtonOutlined = styled("button")(
  css({
    backgroundColor: "white",
    border: `3px solid ${theme.colors.primaryColor}`,
    color: `${theme.colors.primaryColor}`,
    borderRadius: "4px",
    padding: "8px 25px",
    textTransform: "uppercase",
    fontSize: "14px",
  }),
);
