import React from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, select } from "redux-saga/effects";
import { GET, POST } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import {
  GET_ALL_PRODUCTS,
  BUY_PRODUCT,
  RESCUE,
  RESCUE_DESPATCHED,
  RESCUE_NOT_DESPATCHED,
  baseURLV2
} from "utils/constants/endpoints";
import { Types as AlertTypes } from "../ducks/modalAlert";
import { Types } from "../ducks/store";
import { style } from "../../style";


const getProductsActual = (state) => state.store.products;
const pageActual = (state) => state.store.page;

const getProductsDespatchedActual = (state) => state.store.productsDespatched;
const getProductsNotDespatchedActual = (state) => state.store.productsNotDespatched;

const API_V2 = Axios.create({
  baseURL: baseURLV2,
  headers: {
    "Content-Type": "application/json",
    "access-token": `${localStorage.getItem("access-token")}`,
    uid: `${localStorage.getItem("uid")}`,
    client: `${localStorage.getItem("client")}`,
    "resource-type": `${localStorage.getItem("resource-type")}`,
    "host-header": `${style.api.hostHeader}`,
  },
});


function* getProducts() {
  const page = yield select(pageActual);
  try {
    const products = yield requestAPI({
      verb: GET,
      endPoint: `${GET_ALL_PRODUCTS}?page=${page}&per_page=25`,
    });

    const actualList = page === 1 ? [] : yield select(getProductsActual);
    const newList = actualList.concat(products);
    const hasMore = products.length === 25;
    const nextpage = page + 1;

    yield put({
      products: newList,
      hasMore,
      page: nextpage,
      type: Types.GET_PRODUCTS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PRODUCTS_FAIL,
      ...error.response.data,
    });
  }
}

function* getDetailsProduct({ id }) {
  try {
    const product = yield requestAPI({
      verb: GET,
      endPoint: GET_ALL_PRODUCTS,
      id,
    });
    yield put({
      product,
      type: Types.GET_DETAILS_PRODUCT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_DETAILS_PRODUCT_FAIL,
      ...error.response.data,
    });
  }
}

function* buyProduct({ payload }) {
  try {
    yield requestAPI({
      verb: POST,
      endPoint: BUY_PRODUCT,
      data: payload,
    });
    yield put({
      type: Types.BUY_PRODUCT_SUCCESS,
    });
  } catch (error) {
    const messages = {
      type: "errors",
      title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
      msg: error.response.data[Object.keys(error.response.data)],
    };
    yield put({ type: AlertTypes.SET_ALERTS_REQUEST, messages });
    yield put({
      type: Types.BUY_PRODUCT_FAIL,
      ...error.response.data,
    });
  }
}

function* getRescue() {
  try {
    const rescue = yield requestAPI({
      verb: GET,
      endPoint: RESCUE,
    });
    yield put({
      rescue,
      type: Types.GET_RESCUE_SUCCESS,
    });
  } catch (error) {
    const errorMessage = error.response?.data || error;
    yield put({
      type: Types.GET_RESCUE_FAIL,
      errorMessage,
    });
  }
}

function* getRescueDespatched({ payload }) {
  const { page = 1 } = payload;

  try {
    const data = yield API_V2({
      url: RESCUE_DESPATCHED(page),
      method: "GET",
    });

    const {pagination, products} = data?.data;

    const actualList = page === 1
      ? []
      : yield select(getProductsDespatchedActual);
    const newList = actualList.concat(products);
    const hasMore = products.length === 10;

    const action = {
      type: Types.GET_RESCUE_DESPATCHED_SUCCESS,
      productsDespatched: newList,
      paginationDespatched: pagination,
      pageDespatched: page,
      hasMoreDespatched:hasMore
    };

    yield put({ ...action });

  } catch (error) {
    yield put({
      error,
      type: Types.GET_RESCUE_DESPATCHED_FAIL,
    });
  }
}

function* getRescueNotDespatched({ payload }) {
  const { page = 1 } = payload;

  try {
    const data = yield API_V2({
      url: RESCUE_NOT_DESPATCHED(page),
      method: "GET",
    });

    const {pagination, products} = data?.data;

    const actualList = page === 1
      ? []
      : yield select(getProductsNotDespatchedActual);
    const newList = actualList.concat(products);
    const hasMore = products.length === 10;

    const action = {
      type: Types.GET_RESCUE_NOT_DESPATCHED_SUCCESS,
      productsNotDespatched: newList,
      paginationNotDespatched: pagination,
      pageNotDespatched: page,
      hasMoreNotDespatched:hasMore
    };

    yield put({ ...action });

  } catch (error) {
    yield put({
      error,
      type: Types.GET_RESCUE_NOT_DESPATCHED_FAIL,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_PRODUCTS, getProducts);
  yield takeLatest(Types.GET_DETAILS_PRODUCT, getDetailsProduct);
  yield takeLatest(Types.BUY_PRODUCT, buyProduct);
  yield takeLatest(Types.GET_RESCUE, getRescue);
  yield takeLatest(Types.GET_RESCUE_DESPATCHED, getRescueDespatched);
  yield takeLatest(Types.GET_RESCUE_NOT_DESPATCHED, getRescueNotDespatched);
}
