import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import ManagerFiles from "components/presentation/ManagerFiles";
import GalleryFiles from "components/presentation/GalleryFiles";
import { Creators as FilesActions } from "store/ducks/files";
import { Creators as GalleryActions } from "store/ducks/gallery";
import { useAnalytics } from "utils/hooks";
import { PageTitle } from "components/core/Typography/Typography";

import { ReactComponent as RepeatIcon } from "assets/img/icons/repeatIcon.svg";

import { ContainerSelectedOption } from "./FilesStyle";

useAnalytics("screen_directories");

const Files = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const intl = useIntl();
  const location = useLocation();
  const { pageTitle } = location.state || "" ;
  const [selecteShow, setSelecteShow] = useState(type === "galeria");
  const [scrollParentRef, setScrollParentRef] = useState();
  const {
    folders,
    repository,
    searchingFor,
    isLoading,
    hasMore,
    page,
  } = useSelector((state) => state.files);
  const {
    galleryList,
    isLoadingGallery,
    hasMoreGallery,
    pageGallery,
  } = useSelector((state) => state.gallery);
  const dispatchGetFiles = useCallback(
    () => dispatch(FilesActions.getFiles()),
    [dispatch],
  );

  const dispatchGetGallery = useCallback(
    () => dispatch(GalleryActions.getGallery()),
    [dispatch],
  );

  useEffect(() => {
    if (selecteShow) {
      dispatchGetGallery();
    } else {
      dispatchGetFiles();
    }
  }, [selecteShow, dispatchGetFiles, dispatchGetGallery]);

  const fetchMoreData = () => {
    dispatch(FilesActions.getFiles());
  };

  return (
    <Portal setRef={setScrollParentRef}>
      <Flex flexDirection="column">
        {searchingFor ? (
          <ManagerFiles repository={repository} />
        ) : (
          <>
            <PageTitle
              title={
                selecteShow
                  ? intl.messages["menu.gallery"]
                  : pageTitle || intl.messages["menu.files"]
              }
            >
              <ContainerSelectedOption
                onClick={() => setSelecteShow(!selecteShow)}
              >
                <RepeatIcon />
                {selecteShow ? "arquivos" : "galeria"}
              </ContainerSelectedOption>
            </PageTitle>
            {selecteShow ? (
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMoreGallery}
                useWindow={false}
                threshold={1}
                getScrollParent={() => scrollParentRef}
              >
                <GalleryFiles
                  galleryList={galleryList}
                  pageGallery={pageGallery}
                  isLoadingGallery={isLoadingGallery}
                />
              </InfiniteScroll>
            ) : (
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={fetchMoreData}
                hasMore={hasMore}
                useWindow={false}
                threshold={1}
                getScrollParent={() => scrollParentRef}
              >
                <ManagerFiles
                  folders={folders}
                  page={page}
                  isLoading={isLoading}
                />
              </InfiniteScroll>
            )}
          </>
        )}
      </Flex>
    </Portal>
  );
};

export default Files;
