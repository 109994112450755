/* eslint-disable import/no-cycle */
import React from "react";
import { style } from "style";
import PropTypes from "prop-types";
// import Flex from "components/core/Grid/Flex";
import BirthdaysList from "./BirthdaysList";
import EventList from "./EventsList";
import WeekScore from "./WeekScore";
import {ContainerSidebar} from "./SidebarStyle";

const Sidebar = ({ eventList, birthdaysList, weekScore, dateClick }) => (
  <ContainerSidebar>
    {eventList && <EventList dateClick={dateClick} />}
    {weekScore &&
      style.variables.gamificationEnabled &&
      localStorage.getItem("roles") !== "guest" && <WeekScore />}
    {birthdaysList && (style.names.nameApp !== "Pintou Parceria Suvinil") && <BirthdaysList dateClick={dateClick} />}
  </ContainerSidebar>
);

Sidebar.defaultProps = {
  eventList: false,
  birthdaysList: false,
  weekScore: false,
  dateClick: false,
};

Sidebar.propTypes = {
  eventList: PropTypes.bool,
  birthdaysList: PropTypes.bool,
  weekScore: PropTypes.bool,
  dateClick: PropTypes.string,
};

export default Sidebar;
