import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";

const SkeletonGallery = () => {
  const itens = [];
  for (let i = 0; i < 16; i++) {
    itens.push(
      <Flex flexDirection="row" spaceChildren={2} mb={4} ml={12}>
        <Skeleton duration={0.8} width={250} height={250} />
        <Skeleton duration={0.8} width={300} height={350} />
        <Skeleton duration={0.8} width={400} height={240} />
        <Skeleton duration={0.8} width={270} height={350} />
        <Skeleton duration={0.8} width={250} height={250} />
      </Flex>,
    );
  }
  return itens;
};

export default SkeletonGallery;
