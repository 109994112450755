import { takeLatest, put, select } from "redux-saga/effects";
import { NOTIFICATIONS } from "utils/constants/endpoints";
import { Types } from "../ducks/notifications";
import { API_V2 } from "../../utils/API/API";


const getNotificationsActual = (state) => state.notifications.notifications;

function* getNotifications({payload}) {
  const { page } = payload;
  try {

    const {data : {notifications} } = yield API_V2({
      url: `${NOTIFICATIONS}?page=${page}`,
      method: "GET",
    });

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? []
        : yield select(getNotificationsActual);
    const newList = actualList.concat(notifications);
    const hasMore = notifications.length === 10;

    yield put({
      notifications: newList,
      hasMore: hasMore,
      type: Types.GET_NOTIFICATIONS_SUCCESS,
      page,
    });

  } catch (error) {
    yield put({
      type: Types.GET_NOTIFICATIONS_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_NOTIFICATIONS, getNotifications);
}
