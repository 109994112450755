/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";
import Flex from "components/core/Grid/Flex";
import { ContainerForm } from "../../../components/presentation/EditProfile/EditProfileStyle";
import { SkeletonBox } from "./EditProfileStyle";

const SkeletonEditProfile = () => {
  const skeletonRepeat = 12;
  return (
    <Flex flexDirection="column">
      <ContainerForm>
        <Skeleton circle width={126} height={126} />
        {[...Array(skeletonRepeat)].map((_val, index) => (
          <SkeletonBox key={index}>
            <Skeleton height={46} />
          </SkeletonBox>
        ))}
      </ContainerForm>
    </Flex>
  );
};
export default SkeletonEditProfile;
