import styled from "styled-components";
import css from "@styled-system/css";

import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";

export const GeneralContainer = styled(Flex)(
  css({
    width: "100%",
    marginBottom: "120px",
    padding: "10px",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    a: {
      ":hover": {
        textDecoration: "none",
      },
    },
    "@media (min-width: 768px)": {
      padding: "30px",
    },
    "@media (max-width: 1390px)": {
      alignItems: "flex-start",
    },
  }),
);

export const TextContainer = styled("div")(
  css({
    padding: "0px 20px 10px 10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "@media (min-width: 767px)": {
      maxWidth: "600px",
    },
    pre: {
      marginBottom: 0,
    },
  }),
);

export const Title = styled("h1")(
  css({
    fontSize: "24px",
    lineHeight: "22px",
    fontWeight: "600",
    fontFamily: "textBold",
    color: "backText",
    marginTop: "30px",
  }),
);

export const Text = styled("p")(
  css({
    marginTop: "20px",
    color: "gray",
    fontFamily: "text",
    lineHeight: "28px",
    fontSize: "18px",
  }),
);

export const Image = styled("img")(
  css({
    width: "100%",
    borderRadius: "12px",

    "@media (min-width: 767px)": {
      width: "600px",
    },
  }),
);

export const ContainerLinkButton = styled("div")(
  css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

export const KnowMoreButton = styled(Button)(
  css({
    width: "272px",
    height: "52px",
    margin: "30px 0px 30px 0px",
    textTransform: "capitalize",
    borderRadius: "8px",
    minWidth: 120,
    padding: "16px 96px",
    svg: {
      width: "20px",
      path: {
        stroke: "white",
      },
    },
  }),
);

export const CardContentCarrousel = styled("div")(
  css({
    width: "270px",
    borderRadius: "12px",

    "@media (min-width: 359px) and (max-width: 539px)": {
      width: "350px",
    },

    "@media (min-width: 540px) and (max-width: 766px)": {
      width: "500px",
    },

    "@media (min-width: 767px)": {
      width: "590px",
    },
  }),
);
