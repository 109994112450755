import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as UserActions } from "store/ducks/user";
import EditComponent from "components/presentation/EditProfile";
import Portal from "templates/Portal";
import { useAnalytics } from "utils/hooks";
import SkeletonEditProfile from "./SkeletonEditProfile";

useAnalytics("screen_edit_profile");

const EditProfile = () => {
  const { isLoading } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserActions.getUserToEditRequest());
  }, [dispatch]);

  const updateDataUser = (form) => {
    dispatch(UserActions.updateDataUser(form));
  };

  return (
    <Portal>
      {isLoading ? (
        <SkeletonEditProfile />
      ) : (
        <EditComponent updateDataUser={updateDataUser} />
      )}
    </Portal>
  );
};

export default EditProfile;
