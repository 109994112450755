/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { style } from "style";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import urls from "utils/constants/urls";
import moment from "moment";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";
import { Creators as BirthdaysActions } from "store/ducks/birthdays";
import { Creators as TeamActions } from "store/ducks/team";
import { ReactComponent as NoNextBirthdays } from "assets/newBirthdays.svg";

import { smartGet } from "helpers/sharedHelpers";
import { useAnalytics } from "utils/hooks";
import { MsgDoesNotExist, Overflow, Text } from "../SidebarStyle";
import {
  DateBdays,
  MemberBday,
  SectorUser,
  ContainerNoNextBirthdays,
} from "./BirthdaysListStyle";

useAnalytics("screen_birthday_year");

const BirthdaysList = ({ dateClick }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const date = dateClick || moment();
  const currentDay = smartGet(date, "date");
  const currentMonth = smartGet(date, "month");
  const { allTeams } = useSelector((state) => state.team);
  const { birthdays } = useSelector((state) => state.birthdays);
  const bdaysToday = birthdays[currentMonth]?.members?.filter(
    (member) => currentDay === smartGet(member?.datebirthday, "date"),
  );
  const length = bdaysToday?.length;
  const [overflowHeight, setOverflowHeight] = useState();
  const [overflowHeightEvents, setOverflowHeightEvents] = useState();
  const dispatchGetBirthdays = useCallback(
    () => dispatch(BirthdaysActions.getBirthdays()),
    [dispatch],
  );
  const dispatchGetAllBrandFilter = useCallback(
    (nameTeam) => dispatch(TeamActions.getAllBrandFilter(nameTeam)),
    [dispatch],
  );
  const dispatchGetAllTeams = useCallback(
    () => dispatch(TeamActions.getAllTeams()),
    [dispatch],
  );

  useEffect(() => {
    setOverflowHeightEvents(
      document.body.getBoundingClientRect().height / 2 - 20,
    );
  }, []);
  useEffect(() => {
    setOverflowHeight(document.body.getBoundingClientRect().height / 3 - 88);
  }, []);
  useEffect(() => {
    !dateClick && dispatchGetBirthdays();
  }, [dateClick, dispatchGetBirthdays]);
  useEffect(() => {
    if (!dateClick) {
      if (style.variables.child_profiles?.length > 0) {
        dispatchGetAllBrandFilter(style.names.nameApp);
      } else {
        dispatchGetAllTeams();
      }
    }
  }, [dateClick, dispatchGetAllTeams, dispatchGetAllBrandFilter]);

  return (
    <Flex id="sectionBirthdays" flex={3} flexDirection="column" width="100%">
      <PageTitle
        title={intl.messages["calendar.textPage.birthdaysOfTheDay"]}
        fontSize={3}
      >
        <DateBdays>{moment(date).utc().format("DD/MM/YYYY")}</DateBdays>
      </PageTitle>
      <Overflow
        flexDirection="column"
        spaceChildren={3}
        height={`${(dateClick && overflowHeightEvents) || overflowHeight}px`}
      >
        {length > 0 ? (
          bdaysToday.map((birthdayUsers) => {
            const { id, name, image } = birthdayUsers;
            const sector = allTeams.filter(
              (sector) => sector.teamId === birthdayUsers.sector_id,
            );
            return (
              <MemberBday to={`${urls.ROUTES.PROFILE}/${id}`} key={id}>
                <Avatar url={image.url} />
                <Flex spaceChildren={0} flexDirection="column">
                  <Text>{name}</Text>
                  <SectorUser>{sector[0]?.teamName}</SectorUser>
                </Flex>
              </MemberBday>
            );
          })
        ) : (
          <Flex p={2} alignItems="center">
            <ContainerNoNextBirthdays>
              <NoNextBirthdays />
              <MsgDoesNotExist>
                {intl.messages["calendar.textPage.noBirthdayForThatDay"]}.
              </MsgDoesNotExist>
            </ContainerNoNextBirthdays>
          </Flex>
        )}
      </Overflow>
    </Flex>
  );
};

BirthdaysList.propTypes = {
  dateClick: PropTypes.string,
};

export default BirthdaysList;
