/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import urls from "utils/constants/urls";
import { convertToSlug } from "helpers/sharedHelpers";

import NotFoundGeneric from "../NotFoundGeneric";
import SkeletonFiles from "../ManagerFiles/SkeletonFiles";

import * as S from "./GalleryFilesStyle";

const GalleryFiles = ({ galleryList, isLoadingGallery }) => {
  const intl = useIntl();
  return (
    <S.ContainerGallery>
      {isLoadingGallery ? (
        <S.BodyGallery>
          <SkeletonFiles />
        </S.BodyGallery>
      ) : galleryList?.length > 0 ? (
        <S.BodyGallery>
          {galleryList?.map(({ id, name, cover }) => (
            <Link
              title={`${intl.messages["general.textPage.acessToFolder"]} ${name}`}
              to={`${urls.ROUTES.DETAILS_GALLERY}/${convertToSlug(name)}/${id}`}
            >
              <S.ContentFolderGallery key={id}>
                <S.FolderGallery>
                  {cover && <S.imgCoverFolder alt={name} src={cover.url} />}
                </S.FolderGallery>
                <S.NameFolderGallery>{name}</S.NameFolderGallery>
              </S.ContentFolderGallery>
            </Link>
          ))}
        </S.BodyGallery>
      ) : (
        <NotFoundGeneric
          textPage={intl.messages["arquives.textPage.notGalleryFound"]}
        />
      )}
    </S.ContainerGallery>
  );
};

GalleryFiles.defaultProps = {
  isLoadingGallery: false,
  galleryList: [],
};

GalleryFiles.propTypes = {
  isLoadingGallery: PropTypes.bool,
  galleryList: PropTypes.arrayOf(PropTypes.object),
};

export default GalleryFiles;
