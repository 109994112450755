import { takeLatest, put } from "redux-saga/effects";
import { GET } from "utils/constants/verbs";
import {
  GET_USER,
  GET_ALL_TEAMS,
  GET_BRAND_FILTER,
} from "utils/constants/endpoints";
import { requestAPI } from "helpers/requestHelpers";
import { Types } from "../ducks/team";

function* getAllTeams() {
  try {
    const sectors = yield requestAPI({
      verb: GET,
      endPoint: GET_ALL_TEAMS,
    });

    const allTeams = yield sectors.map(({ id, name, users }) => ({
      teamId: id,
      teamName: name,
      teamMembers: users.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.image.url,
        birthday: member.datebirthday,
      })),
    }));

    yield put({
      allTeams,
      type: Types.GET_ALL_TEAMS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_TEAMS_FAIL,
      ...error.response.data,
    });
  }
}

function* getProfile({ id }) {
  try {
    const selectedProfile = yield requestAPI({
      verb: GET,
      endPoint: GET_USER(id),
    });

    yield put({
      selectedProfile,
      type: Types.GET_PROFILE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_PROFILE_FAIL,
      ...error.response.data,
    });
  }
}

function* getAllBrandFilter({ nameTeam }) {
  try {
    const selectedBrand = yield requestAPI({
      verb: GET,
      endPoint: `${GET_BRAND_FILTER}?brand_name=${nameTeam}`,
    });

    const allBrand = yield selectedBrand.map(({ id, name, users }) => ({
      teamId: id,
      teamName: name,
      teamMembers: users.map((member) => ({
        id: member.id,
        name: member.name,
        image: member.image.url,
        birthday: member.datebirthday,
      })),
    }));
    yield put({
      allTeams: allBrand,
      type: Types.GET_ALL_BRAND_FILTER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: Types.GET_ALL_BRAND_FILTER_FAIL,
      ...error.response.data,
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(Types.GET_ALL_TEAMS, getAllTeams);
  yield takeLatest(Types.GET_PROFILE, getProfile);
  yield takeLatest(Types.GET_ALL_BRAND_FILTER, getAllBrandFilter);
}
