import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";
import * as S from "./CertificateStyle";

const CourseSkeleton = () => {
  return (
    <S.CertificateKind>
      <Skeleton circle={true} duration={0.8} width="140px" height="140px" />
      <h1>
        <Skeleton duration={0.8} width={388} height={57} />
      </h1>
      <h4>
        <Skeleton duration={0.8} width={380} height={19} />
      </h4>
      <h3>
        <Skeleton duration={0.8} width={59} height={24} />
      </h3>

      <Flex mt={4} spaceChildren={3}>
        <Skeleton duration={0.8} width={192} height={32} />

        <Skeleton duration={0.8} width={192} height={32} />
      </Flex>
    </S.CertificateKind>
  );
};

export default CourseSkeleton;
