/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import { manageClasses } from "helpers/sharedHelpers";

import { Creators as AcademyActions } from "store/ducks/academy";
import CourseProgressSkeleton from "./CourseProgressSkeleton";

import * as S from "./CourseProgressStyle";
import {
  CourseProgress as CourseStyle,
  CourseProgressPointer,
  CourseProgressCount,
} from "../SidebarAcademyStyle";

const CourseProgress = ({ pageView }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [modulePage, setModulePage] = useState(null);
  const [selectPage, setSelectPage] = useState(false);
  const [submodPage, setSubmodPage] = useState(null);

  const startCourseProgress = 138;

  const { course_details: course, doneSubmodules, isLoading } = useSelector(
    ({ academy }) => academy,
  );

  const {
    title,
    module_courses: courseModules,
    user_status: userStatus,
  } = course;

  useEffect(() => {
    if (isLoading) {
      dispatch(AcademyActions.getDetailsCourse(id));
    }
  }, [isLoading, dispatch, id]);

  useEffect(() => {
    if (selectPage) {
      pageView(modulePage, submodPage);
      setSelectPage(false);
    }
  }, [selectPage, pageView, modulePage, submodPage]);

  const page = (idModule, idSubMod) => {
    setModulePage(idModule);
    setSubmodPage(idSubMod);
    setSelectPage(true);
  };

  const toggleCollapse = (target, index) => {
    const { height } = target.querySelector("ul").getBoundingClientRect();
    const svg = document.getElementById(`svg-${index}`);
    target.classList.toggle("uncollapsed");
    const hr = document.getElementById(`hr-${index}`);

    if (target.classList.contains("uncollapsed")) {
      target.style.height = `${48 + height}px`;
      svg.style.transform = "rotate(90deg)";
      if (hr) {
        hr.style.margin = `-${height}px 0 0 23px`;
        hr.style.height = `${height}px`;
      }
    } else {
      target.style.height = "48px";
      svg.style.transform = "rotate(-90deg)";
      if (hr) {
        hr.style.margin = "0 0 -3px 23px";
        hr.style.height = `16px`;
      }
    }
  };

  const renderModule = (
    { id: moduleId, name, was_finished: completed, submodules },
    index,
  ) => {
    const inProgress =
      (!completed && !!submodules.find(({ was_seen }) => was_seen)) ||
      !!submodules.find(({ id }) => doneSubmodules.find((done) => done === id));
    const progressModule = () => {
      const submodulesFinish = submodules.filter(({ was_seen }) => was_seen);
      if (submodulesFinish.length > 0) {
        return (submodulesFinish.length / submodules.length) * 100;
      }
      return 0;
    };
    const progressModuleValue = progressModule();
    return (
      <>
        <S.CourseModule
          key={moduleId}
          variant={manageClasses({ completed, inProgress })}
          onClick={({ currentTarget }) => toggleCollapse(currentTarget, index)}
        >
          <S.CourseModuleFlex
            variant={manageClasses({ completed, inProgress })}
          >
            <S.CourseInfo
              spaceChildren={2}
              completed={progressModuleValue === 100}
            >
              <CourseStyle>
                <CourseProgressPointer
                  progress={
                    startCourseProgress -
                    (startCourseProgress * progressModuleValue) / 100
                  }
                >
                  <circle cx={22} cy={22} r={22} />
                  <circle cx={22} cy={22} r={22} />
                </CourseProgressPointer>
                <CourseProgressCount>
                  {`${parseInt(progressModuleValue, 10)}%`}
                </CourseProgressCount>
              </CourseStyle>
            </S.CourseInfo>
            <S.CourseModuleTitle>{name}</S.CourseModuleTitle>
            <ArrowLeftIcon id={`svg-${index}`} />
          </S.CourseModuleFlex>
          <S.CourseTasksList>
            {submodules.map(
              ({ id, name: taskTitle, was_seen: completedSub }, taskIndex) => {
                const completedDone = !!doneSubmodules.find(
                  (done) => done === id,
                );
                const completedS = completedDone || completedSub;

                const inSubProgress =
                  taskIndex !== 0 &&
                  !completedSub &&
                  submodules[taskIndex - 1].was_seen;
                const inProgressDone =
                  taskIndex !== 0 &&
                  !completedDone &&
                  !!doneSubmodules.find(
                    (done) => done === submodules[taskIndex - 1].id,
                  );
                const inSProgress = inProgressDone || inSubProgress;

                return (
                  <S.CourseTask
                    key={id}
                    variant={manageClasses({ completedS, inSProgress })}
                  >
                    <S.CourseTaskLink
                      completed={completedSub}
                      onClick={() => completedSub && page(index, taskIndex)}
                    >
                      <span>
                        {index + 1}.{taskIndex + 1}
                      </span>
                      {` ${taskTitle}`}
                    </S.CourseTaskLink>
                    {submodules.length !== taskIndex + 1 && <hr />}
                  </S.CourseTask>
                );
              },
            )}
          </S.CourseTasksList>
        </S.CourseModule>
        {courseModules.length !== index + 1 && <hr id={`hr-${index}`} />}
      </>
    );
  };

  return isLoading ? (
    <CourseProgressSkeleton />
  ) : (
    <>
      <S.CourseContainer>
        <S.CourseInfo
          spaceChildren={2}
          completed={userStatus?.status === "finished"}
        >
          <S.CourseTitle>{title}</S.CourseTitle>
          <CourseStyle>
            <CourseProgressPointer
              progress={
                startCourseProgress -
                (startCourseProgress * userStatus?.progress_rate) / 100
              }
            >
              <circle cx={22} cy={22} r={22} />
              <circle cx={22} cy={22} r={22} />
            </CourseProgressPointer>
            <CourseProgressCount>
              {`${parseInt(userStatus?.progress_rate, 10)}%`}
            </CourseProgressCount>
          </CourseStyle>
        </S.CourseInfo>

        <S.CourseModulesList>
          {courseModules?.map((courseModule, moduleIndex) =>
            renderModule(courseModule, moduleIndex),
          )}
        </S.CourseModulesList>
      </S.CourseContainer>
    </>
  );
};

CourseProgress.defaultProps = {
  pageView: null,
};

CourseProgress.propTypes = {
  pageView: PropTypes.func,
};

export default CourseProgress;
