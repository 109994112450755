import React, { useState, useRef, useLayoutEffect } from "react";
import Skeleton from "react-loading-skeleton";

import { CoursesList } from "./AcademyStyle";

const AcademySkeleton = () => {
  const listRef = useRef(null);
  const [items, setItems] = useState([]);

  useLayoutEffect(() => {
    for (var i = 0; i < 3; i++) {
      setItems((oldState) => [...oldState, <Skeleton duration={0.8} width={228} height={237} />]);
    }
  }, []);

  return <CoursesList ref={listRef}>{items}</CoursesList>;
};

export default AcademySkeleton;
