import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";

import Flex from "components/core/Grid/Flex";

export const CourseProgress = styled(Flex)(
  css({
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    transform: "rotateZ(-90deg)",
  }),
);

export const CourseProgressPointer = styled("svg")(
  css({
    width: "48px",
    height: "48px",
    "> circle": {
      fill: "none",
      strokeWidth: "3px",
      transform: "translate(2px, 2px)",
      strokeDasharray: 138,
      strokeDashoffset: 138,
      transition: "all ease-out .6s",

      "&:first-child": {
        strokeDashoffset: 0,
        stroke: theme.colors.paleGrey,

        "@media(max-width: 768px)": { stroke: "white" },
      },
      "&:last-child": {
        transform: "translate(2px, 2px)",
      },
    },
  }),
  ({ progress }) =>
    css({
      "> circle:last-child": {
        strokeDashoffset: `${progress}`,
      },
    }),
);

export const CourseProgressCount = styled("h3")(
  css({
    position: "absolute",
    fontSize: 1,
    marginBottom: 0,
    fontFamily: "text",
    transform: "rotateZ(90deg)",
  }),
);
