import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getMenu: ["payload"],

  clean: [],
});

const INITIAL_STATE = {
  ordered_menus: [],
};

const getMenu = (state, { payload }) => ({
  ...state,
  ordered_menus: payload,
});

const clean = () => ({
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_MENU]: getMenu,

  [Types.CLEAN]: clean,
});
