import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { CheckBox } from "components/core/Form/Form";
import Button from "components/core/Button";

import { manageClasses } from "helpers/sharedHelpers";

const Quiz = ({ contentId, options, handleQuiz, sendQuiz, allVotesAmount ,endDate }) => {
  const [firstRender, setFirstRender] = useState(true);
  const [quiz, setQuiz] = useState({
    canvas_content_id: contentId,
    surveys_ids: [],
  });
  const userId = localStorage.getItem("idUser");
  const date = useState(new Date());

  const hasUserVoted = useCallback(
    (usersWhoVoted = []) =>
      !!usersWhoVoted?.find((user) => Number(user.id) === Number(userId)),
    [userId],
  );
  const hasBeenVoted = useCallback(
    () =>
      options.find((op) => op.voted || hasUserVoted(op.users_who_voted)) !==
        undefined && true,

    [options, hasUserVoted],
  );
  const addValue = (value) =>
    setQuiz({ ...quiz, surveys_ids: [...quiz.surveys_ids, value] });
  const removeValue = (value) =>
    setQuiz({
      ...quiz,
      surveys_ids: quiz.surveys_ids.filter((op) => op !== value),
    });
  const quizExpired = useMemo(() => Date.parse(endDate) < Date.parse(date[0]), [
    endDate,
    date,
  ]);

  const handleState = (value, checked) => {
    if (checked) {
      addValue(value);
    } else {
      removeValue(value);
    }
  };

  useEffect(() => {
    !firstRender && handleQuiz(quiz);
    setFirstRender(false);
    // eslint-disable-next-line
  }, [quiz]);

  return (
    <>
      {options.map(({ id, title, voted, votes_amount,users_who_voted: usersWhoVoted }) => (
        <CheckBox
          disabled={hasBeenVoted() || quizExpired}
          key={id}
          id={id}
          label={title}
          checked={voted || hasUserVoted(usersWhoVoted)}
          onChange={({ target: { value, checked } }) =>
            handleState(Number(value), checked)
          }
          allVotesAmount={allVotesAmount}
          votesAmount={votes_amount || usersWhoVoted?.length || 0}
          group={`quiz${contentId}--option${id}`}
          itsACheckboxQuiz={true}
        />
      ))}
      {sendQuiz &&
        !hasBeenVoted() &&
        !quizExpired &&
        quiz.surveys_ids.length > 0 && (
          <Button
            variant={manageClasses("big")}
            action={sendQuiz}
            name="Enviar"
          />
        )}
    </>
  );
};

Quiz.propTypes = {
  contentId: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  handleQuiz: PropTypes.func.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default Quiz;
