import { useEffect } from "react";
import { style } from "style";
import { LOGIN } from "utils/constants/routers";
import { authenticatedSamlLogout } from "utils/constants/authenticatedSaml";

export default function Logout() {
  const executeLogout = () => {
    const rememberMe = localStorage.getItem("rememberMe");
    const idUser = localStorage.getItem("idUser");
    const localConfig =
      JSON.parse(localStorage.getItem(`localConfig-${idUser}`)) || {};

    if (style.api.samlUrl) {
      authenticatedSamlLogout();
    } else if (
      rememberMe ||
      localConfig.videoAutoplay ||
      localConfig.notification
    ) {
      localStorage.removeItem("access-token");
      localStorage.removeItem("resource-type");
      localStorage.removeItem("client");
    } else {
      localStorage.removeItem("idUser");
      localStorage.removeItem("resource-type");
      localStorage.removeItem("access-token");
      localStorage.removeItem("host-header");
      localStorage.removeItem("client");
      localStorage.removeItem("uid");
      localStorage.removeItem(`localConfig-${idUser}`);
    }
    window.location.href = LOGIN;
  };
  useEffect(() => {
    executeLogout();
  }, []);
  return null;
}
