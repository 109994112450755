/* eslint-disable react/button-has-type */
/* eslint-disable react/prop-types */
import React from "react";
import urls from "utils/constants/urls";
import { history, convertToSlug } from "helpers/sharedHelpers";

import Flex from "components/core/Grid/Flex";

import * as S from "./CompaniesPublicationStyle";

const CompaniesPublication = ({ ListSideBar, idSubMenu }) => (
  <Flex flexDirection="column" p={4}>
    <S.OurCompaniesList>
      {ListSideBar?.map(
        ({
          title: titleDetails,
          image,
          caption,
          description,
          id: idDetails,
        }) => (
          <S.CompanyCard
            onClick={() =>
              history.push(
                `${urls.ROUTES.OUR_COMPANIES_PUBLICATION}/${convertToSlug(
                  titleDetails,
                )}/${idSubMenu}/${idDetails}`,
              )
            }
          >
            <img src={image?.url} alt={titleDetails} />
            <S.CompanyInfo>
              <h1>{titleDetails}</h1>
              <h2>{caption}</h2>
              <p>{description}</p>
            </S.CompanyInfo>
          </S.CompanyCard>
        ),
      )}
    </S.OurCompaniesList>
  </Flex>
);

export default CompaniesPublication;
