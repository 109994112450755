import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";
import Flex from "components/core/Grid/Flex";

export const Tags = styled(Flex)(
  css({
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px 20px 10px 10px",
    maxWidth: "465px",

    "@media (min-width: 767px)": {
      maxWidth: "100%",
    },
  }),
);

export const Unit = styled("h3")(
  css({
    m: 1,
    fontSize: 1,
    p: "4px 10px",
    color: "white",
    borderRadius: 8,
    textAlign: "center",
    whiteSpace: "nowrap",
    fontFamily: `${theme.fonts.text}`,
    backgroundColor: "secondaryColor",
  }),
);

export const SinalPlus = styled(Unit)(
  css({
    p: 0,
    mx: 2,
    width: 24,
    height: 24,
    fontSize: 3,
    backgroundColor: "primaryColor",
  }),
);
