import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getScoresHistoryUser: [],
  getScoresHistoryUserSuccess: ["payload", "isLoading"],
  getScoresHistoryUserFail: ["isLoading", "error"],
});

const INITIAL_STATE = {
  scoreUser: [],
  isLoading: true,
  error: [],
};

const getScoresHistoryUser = (state) => ({ ...state });

const getScoresHistoryUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getScoresHistoryUserFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_SCORES_HISTORY_USER]: getScoresHistoryUser,
  [Types.GET_SCORES_HISTORY_USER_SUCCESS]: getScoresHistoryUserSuccess,
  [Types.GET_SCORES_HISTORY_USER_FAIL]: getScoresHistoryUserFail,
});
