/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import urls from "utils/constants/urls";
import { history, convertToSlug } from "helpers/sharedHelpers";
import { Creators as ActionGroupPardini } from "store/ducks/groupPardini";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Carousel from "components/core/Carousel";
import { PageTitle } from "components/core/Typography/Typography";

import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";

import SkeletonPublication from "containers/Mural/Publication/PublicationSkeleton";
import CompaniesPublication from "./Sidebar/CompaniesPublication";

import * as S from "./OurCompaniesPublicationStyle";

const OurCompaniesPublication = () => {
  const { id, idSubMenu } = useParams();
  const dispatch = useDispatch();
  const { isLoading, detailsPageGroupPardini, subMenuList } = useSelector(
    (state) => state.groupPardini,
  );
  const { title, image, contents } = detailsPageGroupPardini;
  const dispatchGetPardiniDetails = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniDetails(1, id, false));
  }, [dispatch, id]);

  const dispatchGetPardiniSubmenu = useCallback(() => {
    dispatch(ActionGroupPardini.getPardiniDetails(1, idSubMenu, true));
  }, [dispatch, idSubMenu]);

  useEffect(() => {
    dispatchGetPardiniDetails();
  }, [dispatchGetPardiniDetails]);

  useEffect(() => {
    if (detailsPageGroupPardini?.title && !subMenuList?.child_pages) {
      dispatchGetPardiniSubmenu();
    }
  }, [dispatchGetPardiniSubmenu, detailsPageGroupPardini, subMenuList]);

  const renderPostSection = ({
    id: idContent,
    title: titleContent,
    kind,
    text,
    images,
    link,
  }) => {
    switch (kind) {
      case "image":
        return (
          <Flex flexDirection="column" mb={5}>
            <S.Image src="" />
          </Flex>
        );
      case "text":
        return (
          <S.TextContainer>
            <S.Text dangerouslySetInnerHTML={{ __html: text }} />
          </S.TextContainer>
        );
      case "carousel":
        return (
          <S.CardContentCarrousel>
            <Carousel
              key={idContent}
              showButtonCarousel
              itensRender={images.map(
                ({ id: idImage, image: imageCarousel }) => (
                  <S.Image key={idImage} src={imageCarousel.url} />
                ),
              )}
            />
          </S.CardContentCarrousel>
        );
      case "link":
        return (
          <S.ContainerLinkButton>
            <S.KnowMoreButton
              name={titleContent}
              variant={["gradient"]}
              action={() => window.open(`${link}`)}
            />
          </S.ContainerLinkButton>
        );
      default:
        return null;
    }
  };

  return (
    <Portal
      sidebarContext={
        <CompaniesPublication
          ListSideBar={subMenuList.child_pages}
          idSubMenu={idSubMenu}
        />
      }
    >
      <PageTitle title={subMenuList?.title} />
      {isLoading ? (
        <SkeletonPublication />
      ) : (
        <S.GeneralContainer>
          <GoBackButton
            onClick={() =>
              history.push(
                `${urls.ROUTES.OUR_COMPANIES_PARDINI}/${convertToSlug(
                  subMenuList.title,
                )}/${idSubMenu}`,
              )
            }
          >
            <GoBackIcon />
          </GoBackButton>
          <S.Image src={image?.url} alt={title} />
          <S.TextContainer>
            <S.Title>{title}</S.Title>
          </S.TextContainer>
          {contents?.map((content) => renderPostSection(content))}
        </S.GeneralContainer>
      )}
    </Portal>
  );
};

export default OurCompaniesPublication;
