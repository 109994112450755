import styled from "styled-components";
import css from "@styled-system/css";
import Flex from "components/core/Grid/Flex";
import theme from "styles/theme";

export const ContainerHeader = styled("div")(
  css({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    borderBottomWidth: "1px",
    borderBottomStyle: "solid",
    borderBottomColor: "grayDefaultColor",
  }),
);

export const EditProfile = styled("button")(
  css({
    width: "74px",
    height: "42px",
    border: "none",
    margin: "10px 16px 0 0",
    borderRadius: "8px",
    backgroundColor: "paleGrey",
    textAlign: "center",
    a: {
      color: `${theme.colors.secondaryColor} !important`,
      fontFamily: `${theme.fonts.text}`,
      fontWeight: 300,
      fontSize: "18px",
      lineHeight: "24px",
    },
  }),
);

export const ContainerProfile = styled("div")(
  css({
    "@media (max-width: 767px)": {
      height: "1050px",
    },
  }),
);

export const InfoSection = styled(Flex)(
  css({
    flexDirection: "column",
    ":not(:last-of-type)": {
      borderBottom: "1px solid #D1D4E3",
      marginBottom: 3,
      paddingBottom: 3,
    },
  }),
);

export const Username = styled("h1")(
  css({
    textTransform: "Capitalize",
    fontFamily: "heading",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "32px",
    margin: 0,
  }),
);

export const Team = styled("h3")(
  css({
    textTransform: "Capitalize",
    fontFamily: "text",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    margin: 0,
  }),
);

export const Complement = styled("h3")(
  css({
    textTransform: "Capitalize",
    fontFamily: "text",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    margin: 0,
  }),
);

export const Social = styled(Flex)(
  css({
    alignItems: "center",
    mt: 2,
  }),
);

export const InfoLabel = styled("span")(
  css({
    color: "gray",
    fontFamily: "text",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    textTransform: "uppercase",
    marginBottom: 1,
  }),
);

export const InfoValue = styled("span")(
  css({
    fontFamily: "text",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "20px",
    color: "backText",
  }),
  ({ color }) => color && css({ color }),
);
