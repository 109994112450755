import styled from "styled-components";
import css from "@styled-system/css";
import { Link } from "react-router-dom";
import { style } from "style";
import { Text } from "../SidebarStyle";

export const DateBdays = styled("p")(
  css({
    margin: 0,
    fontSize: "14px",
    color: "labelColor",
    fontFamily: "text",
    alignSelf: "center",
  }),
);

export const MemberBday = styled(Link)(
  css({
    display: "flex",
    ":hover": {
      textDecoration: "none",
    },
  }),
);

export const SectorUser = styled(Text)(
  css({
    fontSize: 13,
    color: "labelColor",
  }),
);

export const ContainerNoNextBirthdays = styled("div")(
  css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    svg: {
      height: "80px",
      ".cls-2": {
        fill: style.colors.primaryColor,
        stroke: "none",
      },
    },
    img: {
      height: "80px",
    },
  }),
);
