/* eslint-disable react/no-array-index-key */
import React from "react";
import Skeleton from "react-loading-skeleton";

import Flex from "components/core/Grid/Flex";

const BirthdaysTodaySkeleton = () => {
  const skeletonRepeat = 4;

  return (
    <Flex p={3} spaceChildren={3} width="fit-content">
      {[...Array(skeletonRepeat)].map((_val, index) => (
        <Flex flexDirection="column" key={index}>
          <Skeleton circle duration={0.8} width="120px" height="120px" />
          <Skeleton duration={0.8} width="120px" height="18px" />
        </Flex>
      ))}
    </Flex>
  );
};

export default BirthdaysTodaySkeleton;
