import React from "react";
import { FormattedMessage } from "react-intl";
import { takeLatest, put, select, takeLeading } from "redux-saga/effects";
import { GET, PUT, POST, DELETE } from "utils/constants/verbs";
import { requestAPI } from "helpers/requestHelpers";
import {
  POSTS_PUBLICATIONS,
  MURAL_FORM_ANSWER,
  POST_FAVORITE_PUBLICATION,
  DELETE_FAVORITE_PUBLICATION,
} from "utils/constants/endpoints";
import { Types } from "../ducks/mural";
import { Types as AlertTypes } from "../ducks/modalAlert";

const getPublicationsActual = (state) => state.mural.publications;
const getFavoritePublicationsActual = (state) =>
  state.mural.favoritePublications;

function* publicationsRequest({ payload }) {
  const { search, page, favorite, selectTags } = payload;
  const parseTags = selectTags?.map((tagName) => `tag=${tagName}&`);

  if (favorite)
    yield put({
      type: Types.FAVORITE_PUBLICATIONS_REQUEST,
    });

  try {

    let publicationsFixeds = [];
    if(page === 1){
      const responseFixeds = yield requestAPI({
        verb: GET,
        endPoint: search
          ? `${POSTS_PUBLICATIONS}?fixed=true&only_favorites=${favorite}&title=${search}&${parseTags.join(
              "",
            )}`
          : `${POSTS_PUBLICATIONS}?fixed=true&only_favorites=${favorite}&${parseTags.join(
              "",
            )}`,
        data: null,
      });
  
      publicationsFixeds = responseFixeds.map(
        // eslint-disable-next-line no-shadow
        ({ id, favorite, fixed, title, author, tags, cover, published_at }) => ({
          id,
          favorite,
          fixed,
          kind: cover?.kind || "text",
          title,
          date: published_at,
          dataId: cover?.canvas_content_id,
          data: cover?.url || cover?.surveys || cover?.images_urls,
          allVotesAmount: cover?.votes_amount,
          end_date: cover?.end_date,
          author,
          tags,
        }),
      );
    }
    
    const response = yield requestAPI({
      verb: GET,
      endPoint: search
        ? `${POSTS_PUBLICATIONS}?fixed=false&only_favorites=${favorite}&title=${search}&page=${page}&${parseTags.join(
            "",
          )}`
        : `${POSTS_PUBLICATIONS}?fixed=false&only_favorites=${favorite}&page=${page}&${parseTags.join(
            "",
          )}`,
      data: null,
    });

    const publications = response.map(
      // eslint-disable-next-line no-shadow
      ({ id, favorite, fixed, title, author, tags, cover, published_at }) => ({
        id,
        favorite,
        fixed,
        kind: cover?.kind || "text",
        title,
        date: published_at,
        dataId: cover?.canvas_content_id,
        data: cover?.url || cover?.surveys || cover?.images_urls,
        allVotesAmount: cover?.votes_amount,
        end_date: cover?.end_date,
        author,
        tags,
      }),
    );

    const actualList =
      // eslint-disable-next-line no-nested-ternary
      page === 1
        ? publicationsFixeds
        : favorite
        ? yield select(getFavoritePublicationsActual)
        : yield select(getPublicationsActual);
    const newList = actualList.concat(publications);
    const hasMore = publications.length === 10;

    const action = {
      type: Types.PUBLICATIONS_SUCCESS,
      searchingFor: search,
      selectTags,
    };

    if (favorite) {
      yield put({
        ...action,
        favoritePublications: newList,
        pageFavorites: page,
        hasMoreFavorites: hasMore,
      });
    } else {
      yield put({ ...action, publications: newList, page, hasMore });
    }
  } catch (error) {
    yield put({
      type: Types.PUBLICATIONS_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* quizPublicationsRequest({ payload }) {
  const { idPublication, idForm, answer } = payload;
  try {
    yield requestAPI({
      verb: POST,
      endPoint: `${MURAL_FORM_ANSWER}${idPublication}/canvas_contents/${idForm}/surveys/bulk_vote`,
      data: {
        surveys_ids: answer,
      },
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Sua resposta foi enviada!",
      },
    });

    yield put({
      type: Types.QUIZ_PUBLICATIONS_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(error.response.data)],
      },
    });

    yield put({
      type: Types.QUIZ_PUBLICATIONS_FAIL,
      ...error.response.data,
      isLoading: false,
    });
  }
}

function* surveyPublicationsRequest({ payload }) {
  const { idPublication, idOption } = payload;
  try {
    yield requestAPI({
      verb: PUT,
      endPoint: `${MURAL_FORM_ANSWER}${idPublication}/surveys/${idOption}`,
      data: null,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Sua resposta foi enviada!",
      },
    });

    yield put({
      type: Types.SURVEY_PUBLICATIONS_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(error.response.data)],
      },
    });

    yield put({
      type: Types.SURVEY_PUBLICATIONS_FAIL,
      payload: { error: error.response.data, isLoading: false },
    });
  }
}

function* postFavoritePublicationRequest({ payload }) {
  const { id } = payload;
  try {
    yield requestAPI({
      verb: POST,
      endPoint: POST_FAVORITE_PUBLICATION(id),
      data: null,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Publicação Favoritada com Sucesso !",
      },
    });

    yield put({
      type: Types.POST_FAVORITE_PUBLICATION_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(error.response.data)],
      },
    });

    yield put({
      type: Types.POST_FAVORITE_PUBLICATION_FAIL,
      payload: { error: error.response.data, isLoading: false },
    });
  }
}

function* deleteFavoritePublicationRequest({ payload }) {
  const { id } = payload;
  try {
    yield requestAPI({
      verb: DELETE,
      endPoint: DELETE_FAVORITE_PUBLICATION(id),
      data: null,
    });

    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "success",
        title: <FormattedMessage id="modal.textPage.successModal" />,
        msg: "Publicação Desfavoritada !",
      },
    });

    yield put({
      type: Types.DELETE_FAVORITE_PUBLICATION_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: AlertTypes.SET_ALERTS_REQUEST,
      messages: {
        type: "errors",
        title: <FormattedMessage id="modal.textPage.somethingWentWrong" />,
        msg: error.response.data[Object.keys(error.response.data)],
      },
    });

    yield put({
      type: Types.DELETE_FAVORITE_PUBLICATION_FAIL,
      payload: { error: error.response.data, isLoading: false },
    });
  }
}

export function* watcherSaga() {
  yield takeLeading(Types.PUBLICATIONS_REQUEST, publicationsRequest);
  yield takeLatest(Types.QUIZ_PUBLICATIONS_REQUEST, quizPublicationsRequest);
  // yield takeLatest(Types.FIXEDS_PUBLICATIONS_REQUEST, publicationsFixedsRequest);
  yield takeLatest(
    Types.SURVEY_PUBLICATIONS_REQUEST,
    surveyPublicationsRequest,
  );
  yield takeLatest(
    Types.POST_FAVORITE_PUBLICATION_REQUEST,
    postFavoritePublicationRequest,
  );
  yield takeLatest(
    Types.DELETE_FAVORITE_PUBLICATION_REQUEST,
    deleteFavoritePublicationRequest,
  );
}
